import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Container, IconButton, CircularProgress } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import ReactVideoPlayer from "../../components/ReactVideoPlayer/ReactVideoPlayer";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { deleteProgramme, programmeDetail } from "src/DAL/Programme/Programme";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 48;

function ProgrammesDetail() {
  const params = useParams();
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(false);
  const [programmeDetailInfo, setProgrammeDetailInfo] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => setOpenDelete(false);

  const handleNavigateEdit = (value) => {
    navigate(`/programmes/edit-programme/${params?.id}`, {
      state: params?.id,
    });
  };

  const handleDelete = async () => {
    let result = await deleteProgramme(params?.id);
    if (result.code === 200) {
      handleCloseDelete();
      enqueueSnackbar(result.message, { variant: "success" });
      navigate("/programmes");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      handleCloseDelete();
    }
  };

  const handleDetail = async () => {
    setIsLoading(true);
    let result = await programmeDetail(
      state?.program_slug ? state?.program_slug : params?.id
    );
    if (result.code === 200) {
      setProgrammeDetailInfo(result.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleLockedProgramInfo = () => {
    navigate(`/programmes/locked-programme/${params.id}`, {
      state: programmeDetailInfo,
    });
  };

  const handleNavigateAdd = () => {
    navigate(`/programmes/${"add"}/${programmeDetailInfo._id}`, {
      state: programmeDetailInfo,
    });
  };

  const handleNavigateRemove = () => {
    navigate(`/programmes/${"remove"}/${programmeDetailInfo._id}`, {
      state: programmeDetailInfo,
    });
  };

  const handleManageMember = () => {
    navigate(`/programmes/members/${programmeDetailInfo._id}`, {
      state: programmeDetailInfo,
    });
  };

  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },

    {
      title: programmeDetailInfo?.title,
      navigation: null,
      active: true,
    },
  ];

  useEffect(() => {
    if (location.state !== null) {
      localStorage.setItem("program_name", location.state.name);
      localStorage.setItem("program_description", location.state.description);
    }
    handleDetail();
    setTimeout(() => {}, 5000);
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <Container className="mt-3">
      <CustomConfirmation
        handleOpenDelete={handleOpenDelete}
        setOpen={setOpenDelete}
        handleCloseDelete={handleCloseDelete}
        handleAgree={handleDelete}
        open={openDelete}
        setOpenDelete={setOpenDelete}
      />
      <div className="row mobile-margin display-flex">
        <span>
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </span>
      </div>
      <div className="row section-space">
        <div className="col-8">
          <h2>{programmeDetailInfo?.title}</h2>
        </div>
        <div className="col-4 text-end">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      </div>

      <div className="row media-margin">
        <div className="col-12">
          {programmeDetailInfo?.video_url == "" ? (
            <img
              src={s3baseUrl + programmeDetailInfo.program_images.thumbnail_1}
              style={{ width: "100%" }}
            />
          ) : (
            <ReactVideoPlayer url={programmeDetailInfo?.video_url} />
          )}
        </div>
        <div className="col-12 section-space">
          {programmeDetailInfo?.audio_file == "" ? (
            ""
          ) : (
            <audio
              className="w-100"
              src={s3baseUrl + programmeDetailInfo?.audio_file}
              controls
            />
          )}
        </div>
        <div className="col-12 section-space">
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: programmeDetailInfo?.detailed_description,
              }}
            ></div>
          </p>
        </div>
      </div>

      <div className="row section-space">
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 6.5,
              width: "41ch",
            },
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          className="primary_svg_color"
        >
          <MenuItem
            onClick={() =>
              navigate(
                `/programmes/lessons-listing/${programmeDetailInfo?.program_slug}`,
                {
                  state: {
                    name: programmeDetailInfo?.title,
                    slug: programmeDetailInfo?.program_slug,
                  },
                }
              )
            }
          >
            <EditIcon className="me-3" height="10px" /> Manage Lessons
          </MenuItem>
          <MenuItem onClick={handleManageMember}>
            <EditIcon className="me-3" height="10px" /> Manage Member
          </MenuItem>
          <MenuItem onClick={handleLockedProgramInfo}>
            <EditIcon className="me-3" height="10px" /> Locked Programme Info
          </MenuItem>
          <MenuItem onClick={handleNavigateAdd}>
            <EditIcon className="me-3" height="10px" /> Add Member Programme
            Access
          </MenuItem>
          <MenuItem onClick={handleNavigateRemove}>
            <EditIcon className="me-3" height="10px" /> Remove Member Programme
            Access
          </MenuItem>
          <MenuItem onClick={handleNavigateEdit}>
            <EditIcon className="me-3" height="10px" /> Edit
          </MenuItem>
          <MenuItem onClick={handleOpenDelete}>
            <DeleteIcon className="me-3" height="10px" />
            Delete
          </MenuItem>
        </Menu>
      </div>
    </Container>
  );
}

export default ProgrammesDetail;
