import { Link as RouterLink, Outlet, Navigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
// components
import Logo from "../components/Logo";
import { useEffect } from "react";
import { ProjectInfoApi } from "src/DAL/SiteSetting/siteSetting";
import { usePGIMode } from "src/Hooks/PGIModeContext";

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const { handleProjectInfo } = usePGIMode();

  const getProjectInfo = async () => {
    const result = await ProjectInfoApi();
    if (result.code === 200) {
      handleProjectInfo(result.default_setting);
      localStorage.setItem("favIcon", result.default_setting.admin_favicon);
      localStorage.setItem("adminLogo", result.default_setting.admin_logo);
      localStorage.setItem("metaTitle", result.default_setting.meta_title);
      localStorage.setItem(
        "metaDescription",
        result.default_setting.meta_description
      );
    }
  };

  useEffect(() => {
    getProjectInfo();
  }, []);

  if (localStorage.getItem("token")) {
    return <Navigate to="/dashboard"> </Navigate>;
  }

  return (
    <>
      <HeaderStyle>
        <RouterLink to="/">{/* <Logo /> */}</RouterLink>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
