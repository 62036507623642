import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useSnackbar } from 'notistack';

const PGIModeContext = React.createContext();

export const usePGIMode = () => useContext(PGIModeContext);
export function ContextPGIMode({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */
  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();
  const [mode, setMode] = useState(false);
  const [profileNewImage, setProfileNewImage] = useState();
  const [logo, setLogo] = useState();
  const [metaTitle, setMetaTitle] = useState("");
  const [projectInfo, setProjectInfo] = useState({});
  const [viewMode, setViewMode] = useState(
    localStorage.getItem("view_mode_progamme")
  );

  /* ------------------------------------------------------
  ------------------/ Hooks Functions /--------------------
  ------------------------------------------------------- */

  const handleChangeMode = (value) => {
    setMode(value);
    setProfileNewImage(localStorage.getItem("image"));
  };

  const handleProjectInfo = (value) => {
    setProjectInfo(value);
  };

  useEffect(() => {
    setProfileNewImage(localStorage.getItem("image"));
  }, [localStorage.getItem("image")]);

  useEffect(() => {
    setLogo(localStorage.getItem("adminLogo"));
  }, [localStorage.getItem("adminLogo")]);

  useEffect(() => {
    setMetaTitle(localStorage.getItem("metaTitle"));
  }, [localStorage.getItem("metaTitle")]);

  useEffect(() => {
    localStorage.setItem("view_mode_progamme", viewMode);
  }, [viewMode]);

  const collection = {
    mode,
    handleChangeMode,
    profileNewImage,
    setProfileNewImage,
    handleProjectInfo,
    logo,
    setLogo,
    metaTitle,
    projectInfo,
    viewMode,
    setViewMode,
  };
  return (
    <PGIModeContext.Provider value={collection}>
      {children}
    </PGIModeContext.Provider>
  );
}
