import { useEffect, useState } from "react";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import CircleIcon from "@mui/icons-material/Circle";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";

import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { dd_date_format } from "src/utils/constant";
import Label from "src/components/Label";
import {
  Catagorie_list_api,
  subscription_list_with_page_and_plan,
} from "src/DAL/WebsitePages/websitepages";
import { convertToTitleCase, htmlDecode } from "src/utils/convertHtml";
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  DeleteCatagory,
  DeleteConsultantApi,
} from "src/DAL/consultant/consultant";
import MemberFilter from "../Members/MemberFilter";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import CatagoryFilter from "./CatagoryFilter";
import FilteredChip from "src/components/FilteredChip";

const useStyles = makeStyles(() => ({
  loadingSection: {
    marginLeft: "45%",
    marginTop: "20%",
    marginBottom: "20%",
    padding: 0,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CatagoriesList() {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoadingSection, setIsLoadingSection] = useState(false);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const navigate = useNavigate();
  const EMPTY_FILTER = {
    program: null,
    grandParentCategory: null,
    parentCategory: null,
    search_text: "",
    category_type: {
      chip_label: "All",
      chip_value: "all",
    },
    status: "",
  };

  const ALTER_FILTER = {
    category_type: null,
  };

  const [filterState, setFilterState] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);

  const handleAddCatagories = () => {
    navigate(`/catagory/add-catagory`);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const getMembersList = async (filterData) => {
    setIsLoadingSection(true);
    handleCloseFilterDrawer();

    let postData = { ...filterData };

    if (postData.program) {
      postData.program = filterData.program._id;
    }
    if (postData.grandParentCategory) {
      postData.grandParentCategory = postData.grandParentCategory._id;
    }

    if (postData.parentCategory) {
      postData.parentCategory = postData.parentCategory._id;
    }
    if (postData.search_text) {
      postData.search_text = postData.search_text;
    }
    if (postData.category_type) {
      postData.category_type = postData.category_type.chip_value;
    }
    if (postData.status === "") {
      postData.category_status = "all";
    } else {
      postData.category_status = postData.status;
    }

    delete postData.status;

    const result = await Catagorie_list_api(page, rowsPerPage, postData);
    if (result.code == 200) {
      let chipData = { ...filterData };
      if (chipData.search_text) {
        delete chipData.search_text;
      }
      setFilterStateUpdated(chipData);
      let subscription_list = [];
      if (result.category.length > 0) {
        subscription_list = result.category.map((event, index) => {
          return {
            ...event,
            table_avatar: {
              src: event.category_image.thumbnail_1
                ? s3baseUrl + event.category_image.thumbnail_1
                : "/",
              alt: event.category_name,
              created_at: event.createdAt,
              grandParentCategorys: "n/a",
            },
          };
        });
      }
      setSubscriptionsData(subscription_list);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setIsLoadingSection(false);
    }
  };
  const handleChangeOthers = (event, name) => {
    setFilterState((values) => ({ ...values, [name]: event }));
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterState.search_text = searchText;
    getMembersList(filterState);
    handleCloseFilterDrawer();
    localStorage.setItem("catagory_list_data", JSON.stringify(filterState));
  };
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
    setFilterState(filterStateUpdated);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);

    getMembersList(data);
    setFilterState(data);
    localStorage.setItem("catagory_list_data", JSON.stringify(data));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoadingSection(true);
    const result = await DeleteCatagory(deleteDoc._id);
    if (result.code === 200) {
      filterState.search_text = searchText;
      getMembersList(filterState);
      setIsLoadingSection(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingSection(false);
    }
  };
  const handleEdit = (value) => {
    navigate(`/catagory/edit-catagory/${value.category_slug}`, {
      state: value,
    });
  };

  const handleManageMember = (value) => {
    navigate(`/catagory/catagory-lessons-list/${value?._id}`, {
      state: value,
    });
  };

  const handleClearFilter = () => {
    let clear_data = { ...EMPTY_FILTER, category_type: null };
    setPage(0);
    setPageCount(1);
    setSearchText("");
    setFilterState(clear_data);
    getMembersList(clear_data);
    handleCloseFilterDrawer();
    localStorage.removeItem("catagory_list_data");
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Manage Lessons",
      icon: "akar-icons:edit",
      handleClick: handleManageMember,
    },
  ];
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "category_name",
      label: "Category Name",

      renderData: (row) => {
        const iconStyle = {
          color: "blue !important",
        };
        return (
          <div className="d-flex pointer">
            <Tooltip title={row.status === false ? "Inactive" : "Active"}>
              <CircleIcon
                style={iconStyle}
                className={`transation-status-icon ${
                  row.status === false ? "Inactive-classs" : "Active-class"
                } `}
              />
            </Tooltip>
            <div
              onClick={() => {
                handleManageMember(row);
              }}
            >
              <p>{row.category_name ? row.category_name : "N/A"} </p>
            </div>
          </div>
        );
      },
    },
    {
      id: "table_avatar",
      label: "Catagory Image",
      alignRight: false,
      type: "thumbnail",
    },
    {
      id: "statusDate",
      label: "Programme",
      alignRight: false,
      renderData: (row) => {
        let program = "N/A";
        if (row.program) {
          program = row.program.title;
        }
        return <p className="mb-0">{program}</p>;
      },
    },

    {
      id: "statusDate",
      label: "Grand Parent",
      alignRight: false,
      renderData: (row) => {
        let grandParentCategorys = "N/A";
        if (row.grandParentCategory) {
          grandParentCategorys = row.grandParentCategory.category_name;
        }
        return <p className="mb-0">{grandParentCategorys}</p>;
      },
    },

    {
      id: "statusDate",
      label: "Parent Category",
      alignRight: false,
      renderData: (row) => {
        let parentCategory = "N/A";
        if (row.parentCategory) {
          parentCategory = row.parentCategory.category_name;
        }
        return <p className="mb-0">{parentCategory}</p>;
      },
    },
    { id: "order", label: "Order", alignRight: false },
    { id: "createdAt", label: "Created At", alignRight: false },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("catagory_list_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterState(filter_data);
    setFilterStateUpdated(filter_data);
    getMembersList(filter_data);
  }, [rowsPerPage, page]);

  if (isLoadingSection == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="col-lg-6 col-sm-12 mb-3">
            <h2>Catagories</h2>
          </div>
          <div className="col-lg-6 col-sm-12 mb-3 text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
            <button
              className="small-contained-button"
              onClick={handleAddCatagories}
            >
              Add Catagory
            </button>
          </div>
        </div>
        <FilteredChip
          data={filterStateUpdated}
          tempState={filterState}
          EMPTY_FILTER={EMPTY_FILTER}
          ALTER_FILTER={ALTER_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <div className="row">
          {isLoadingSection ? (
            <CircularProgress
              className={classes.loadingSection}
              color="primary"
            />
          ) : (
            <div className="col-12">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={subscriptionsData}
                MENU_OPTIONS={MENU_OPTIONS}
                className="card-with-background"
                custom_pagination={{
                  total_count: totalCount,
                  rows_per_page: rowsPerPage,
                  page: page,
                  handleChangePage: handleChangePage,
                  onRowsPerPageChange: handleChangeRowsPerPage,
                }}
                custom_search={{
                  searchText: searchText,
                  setSearchText: setSearchText,
                  handleSubmit: searchFunction,
                }}
                pageCount={pageCount}
                totalPages={totalPages}
                handleChangePages={handleChangePages}
                pagePagination={true}
              />
              <CustomConfirmation
                open={openDelete}
                setOpen={setOpenDelete}
                title={"Are you sure you want to delete ?"}
                handleAgree={handleDelete}
              />
              <CustomDrawer
                isOpenDrawer={filterDrawerState}
                onOpenDrawer={handleOpenFilterDrawer}
                onCloseDrawer={handleCloseFilterDrawer}
                pageTitle="Filters"
                componentToPassDown={
                  <CatagoryFilter
                    filterData={filterState}
                    setFilterData={setFilterState}
                    handleChangeOthers={handleChangeOthers}
                    searchSubmitFilter={searchFunction}
                    handleClearFilter={handleClearFilter}
                  />
                }
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
