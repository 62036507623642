// here we add all base urls and keys

export const baseUri = process.env.REACT_APP_API_BASE_URL;
export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;
export const socketUrl = process.env.REACT_APP_SOCKET_URL;
export const project_name = process.env.REACT_APP_PROJECT_NAME;
export const project_url = process.env.REACT_APP_PROJECT_URL;
export const client_url = process.env.REACT_APP_CLIENT_URL;
export const project_type = process.env.REACT_APP_PROJECT_TYPE;
export const clientPortalUrl = process.env.REACT_APP_CLIENT_PORTAL_URL;

export const material_theme_palate = {
  lighter: "#d4bff2",
  light: "#ede7f6",
  main: "#ea7423",
  dark: "#198BA8",
  darker: "#198BA8",
  contrastText: "#fff",
};
