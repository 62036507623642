import * as React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress, Button, Tooltip } from "@mui/material";
import {
  addPaymentPlanApi,
  detailPaymentPlanApi,
  editPaymentPlanApi,
} from "src/DAL/WebsitePages/paymentPlan";
import { page_detail_by_id } from "src/DAL/WebsitePages/websitepages";
import { s3baseUrl } from "src/config/config";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import GeneralCkeditor from "src/components/GeneralCkeditor";
import { formsListApi } from "src/DAL/Forms/Forms";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DesktopDatePicker, TimePicker } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import moment from "moment";
import TimeSlots from "./TimeSlots";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const Input = styled("input")({
  display: "none",
});
export default function AddOrUpdateBookingPaymentPlan() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [forms, setForms] = useState([]);
  const [programmeListings, setProgramListing] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [file, setProfileImage] = React.useState();
  const [formType, setFormType] = useState("ADD");
  const [eventsListing, setEventsListing] = useState([]);
  const [eventsName, setEventsName] = useState([]);
  const [moduleData, setModuleData] = useState({});
  const [oldImage, setOldImage] = useState();
  const [pageData, setPageData] = useState({});
  const module_actual_name = "payment_plans";

  const fileChangedHandler = async (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "823");
    formData.append("height", "363");
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        ["plan_image"]: imageUpload.image_path,
      });
    } else {
      enqueueSnackbar(imageUpload.message, { variant: "error" });
    }
  };
  const [fieldsArray, setFieldsArray] = useState([
    {
      start_time: dayjs(new Date()).$d,
      end_time: dayjs(new Date()).$d,
      total_seats: 1,
    },
  ]);

  const [inputs, setInputs] = useState({
    plan_title: "",
    location: "",
    age_group: 1,
    seats: 1,
    is_plan_free: false,
    plan_package: "",
    number_of_days: "",
    plan_button_text: "",
    active_campaign_tag_id: 0,
    active_campaign_list_id: 0,
    plan_status: true,
    short_description: "",
    detailed_description: "",
    activity: "",
    benefit_description: "",
    plan_currency: "gbp",
    plan_price: 0,
    is_dont_show_full_amount: true,
    stripe_product_name: "",
    payment_access: "onetime",
    payment_method_preferance: "online",
    plan_type: "week",
    time_period_interval: 0,
    trial_period_days: 0,
    initial_amount: 0,
    plan_order: 0,
    installment_amount: 0,
    product: "",
    form_template: "",
    is_basic_membership: false,
    plan_for: "Basic",
    programme: "",
    days: [],
    member: "",
    extra_coins: 0,
    is_new_user_created_for: true,
    first_and_next_recurring_days_diffrence: false,
    days_difference: 0,
    subscriber_count: 0,
    max_teacher_limit: 1,
    plan_image: {},
    start_date: dayjs(new Date()).$d,
    end_date: dayjs(new Date()).$d,
    start_time: dayjs(new Date()).$d,
    end_time: dayjs(new Date()).$d,
  });

  const ChangeCommissonAmount = () => {
    if (inputs.payment_access === "onetime") {
      setInputs((values) => ({
        ...values,
      }));
    } else {
      setInputs((values) => ({
        ...values,
      }));
    }
    return true;
  };

  const renderCurrency = () => {
    return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Plan Currency *</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="plan_currency"
          value={inputs.plan_currency}
          label="Plan currency"
          onChange={handleChange}>
          <MenuItem value="usd">Dollar</MenuItem>
          <MenuItem value="gbp">UK Pounds</MenuItem>
          <MenuItem value="eur">Euro</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const handleChangeDate = (name, event) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: event.$d,
      };
    });
  };

  const formsList = async () => {
    let result = await formsListApi();
    if (result.code == 200) {
      setForms(result.dynamic_form);
      // const data = [
      //   { _id: "12344", name: "hello" },
      //   { _id: "34567", name: "new form" },
      // ];
      // setForms(data);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    const set_commisson = await ChangeCommissonAmount();
    if (set_commisson) {
      const timeSlots = fieldsArray.map((slot) => ({
        start_time: moment(slot.start_time).format("HH:mm"),
        end_time: moment(slot.end_time).format("HH:mm"),
        total_seats: slot.total_seats,
      }));
      let formDatas = {
        plan_title: inputs.plan_title,
        location: inputs.location,
        form_template: inputs.form_template,
        age_group: inputs.age_group,
        // seats: inputs.seats,
        days: inputs.days,
        page_category: "booking",
        timeslot: timeSlots,
        extra_coins: inputs.extra_coins,
        start_date: moment(inputs.start_date).format("YYYY-MM-DD"),
        end_date: moment(inputs.end_date).format("YYYY-MM-DD"),
        // start_time: moment(state.start_time).format("HH:mm"),
        // end_time: moment(state.end_time).format("HH:mm"),
        subscriber_count: "",
        max_teacher_limit: 1,
        is_new_user_created_for: false,
        plan_package: inputs.plan_package,
        plan_button_text: inputs.plan_button_text,
        // active_campaign_tag_id:
        //   inputs.active_campaign_tag_id >= 0
        //     ? inputs.active_campaign_tag_id?.toString()
        //     : "",
        active_campaign_list_id:
          inputs.active_campaign_list_id >= 0
            ? inputs.active_campaign_list_id?.toString()
            : "",
        plan_status: inputs.plan_status,
        is_plan_free: inputs.is_plan_free,
        short_description: inputs.short_description,
        detailed_description: inputs.detailed_description,
        activity: inputs.activity,
        benefit_description: inputs.benefit_description,
        is_basic_membership: false,
        plan_for: "",
        // program: programme_array,
        event: [],
      };
      if (file) {
        formDatas.plan_image = file ? inputs.plan_image : oldImage;
      } else {
        formDatas.plan_image = oldImage;
      }
      if (params && params.page_id) {
        formDatas.created_for = "sale_page";
        formDatas.page_id = params.page_id;
      } else {
        formDatas.created_for = "general";
      }
      if (formType == "EDIT") {
        formDatas.plan_order = inputs.plan_order;
      }

      if (inputs.is_plan_free == false) {
        formDatas.plan_price = inputs.plan_price;
        formDatas.stripe_product_name = inputs.stripe_product_name;
        formDatas.plan_currency = inputs.plan_currency;
        formDatas.payment_access = inputs.payment_access;
        formDatas.payment_method_preferance = inputs.payment_method_preferance;
        if (inputs.payment_access == "recursion") {
          // formDatas.product = inputs.product;
          // formDatas.days_difference = inputs.days_difference;
          // formDatas.first_and_next_recurring_days_diffrence =
          //   inputs.first_and_next_recurring_days_diffrence;
          // formDatas.time_period_interval = inputs.time_period_interval;
          // formDatas.trial_period_days = inputs.trial_period_days;
          formDatas.plan_type =
            inputs.plan_type == undefined ? "week" : inputs.plan_type;
          // formDatas.initial_amount = inputs.initial_amount;
          formDatas.is_dont_show_full_amount = inputs.is_dont_show_full_amount;
          if (inputs.plan_type == "custom") {
            formDatas.number_of_days =
              inputs.number_of_days == undefined ? 0 : inputs.number_of_days;
          }
        }
      }
      const formObject = formDatas;
      setIsLoading(true);
      const result =
        formType == "ADD"
          ? await addPaymentPlanApi(formObject)
          : await editPaymentPlanApi(formObject, params.plan_id);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(-1);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getPageDetail = async () => {
    const result = await page_detail_by_id(params.page_id);
    if (result.code == 200) {
      handlePageDetail(result.sale_page);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getPlanDetail = async () => {
    const result = await detailPaymentPlanApi(params.plan_id);
    if (result.code == 200) {
      handleFormatdata(result.payment_plan);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleFormatdata = (data) => {
    setFormType("EDIT");
    const newArray = data.timeslot
      ? data.timeslot.map(({ start_time, end_time, total_seats }) => ({
          start_time: moment()
            .set({
              hour: parseInt(start_time.split(":")[0]),
              minute: parseInt(start_time.split(":")[1]),
              second: 0,
              millisecond: 0,
            })
            .toISOString(),
          end_time: moment()
            .set({
              hour: parseInt(end_time.split(":")[0]),
              minute: parseInt(end_time.split(":")[1]),
              second: 0,
              millisecond: 0,
            })
            .toISOString(),
          total_seats,
        }))
      : [];
    setFieldsArray(newArray);
    setInputs({
      ...data,
      // product: data?.product ? data.product._id : data.product,
      // form_template: data?.form_template
      //   ? data.form_template
      //   : data.form_template,
    });

    setIsLoading(false);
  };
  const handlePageDetail = (data) => {
    setPageData(data);
    const get_module_info = data.module_info.filter(
      (item) => item.module_actual_name == module_actual_name
    )[0];
    setModuleData(get_module_info.module_replica_info);
  };

  useEffect(() => {
    // getProgrammes();
    formsList();
    console.log(state, "state");
    if (params && params.plan_id) {
      if (state) {
        if (params.page_id) {
          handlePageDetail(state.page_info);
        }
        handleFormatdata(state.data);
      } else {
        getPlanDetail();
        if (params.page_id) {
          getPageDetail();
        }
      }
    } else {
      setIsLoading(false);
      if (params.page_id) {
        if (state) {
          handlePageDetail(state);
        } else {
          getPageDetail();
        }
      }
    }
  }, []);
  useEffect(() => {
    setOldImage(state?.data?.plan_image);
  }, [inputs.plan_price, inputs.time_period_interval, inputs.initial_amount]);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 display-flex mb-4">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <span className="sale-page-title">{pageData.sale_page_title}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          {moduleData?.add_page_heading ? (
            <h2>
              {formType === "ADD"
                ? moduleData.add_page_heading
                : moduleData.edit_page_heading}
            </h2>
          ) : (
            <h2>{`${formType === "ADD" ? "Add" : "Edit"} Plan`}</h2>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Plan Title"
              variant="outlined"
              fullWidth
              required
              name="plan_title"
              value={inputs.plan_title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="School Location"
              variant="outlined"
              fullWidth
              required
              name="location"
              value={inputs.location}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Child's Age Group"
              variant="outlined"
              fullWidth
              required
              type="number"
              name="age_group"
              onWheel={(event) => event.target.blur()}
              value={inputs.age_group}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Form Template
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="form_template"
                value={inputs.form_template}
                label="Form Template"
                onChange={handleChange}>
                {forms.map((form) => {
                  return (
                    <MenuItem value={form._id}>{form.form_title}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Start Date"
                name="start_date"
                inputFormat="DD-MM-YYYY"
                value={inputs.start_date}
                format="DD-MM-YYYY"
                onChange={(e) => handleChangeDate("start_date", e)}
                renderInput={(params) => (
                  <TextField {...params} required={true} fullWidth />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <LocalizationProvider dateAdapter={AdapterDayjs} className="w-100">
              <DesktopDatePicker
                label="End Date"
                name="end_date"
                inputFormat="DD-MM-YYYY"
                value={inputs.end_date}
                format="DD-MM-YYYY"
                className="mt-3"
                onChange={(e) => handleChangeDate("end_date", e)}
                renderInput={(params) => (
                  <TextField {...params} required={true} fullWidth />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Days</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="days"
                value={inputs.days}
                label="Days"
                multiple // Adding multiple prop
                onChange={handleChange}>
                <MenuItem value={"Monday"}>Monday</MenuItem>
                <MenuItem value={"Tuesday"}>Tuesday</MenuItem>
                <MenuItem value={"Wednesday"}>Wednesday</MenuItem>
                <MenuItem value={"Thursday"}>Thursday</MenuItem>
                <MenuItem value={"Friday"}>Friday</MenuItem>
                <MenuItem value={"Saturday"}>Saturday</MenuItem>
                <MenuItem value={"Sunday"}>Sunday</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Plan Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="plan_status"
                value={inputs.plan_status}
                label="Plan Status"
                onChange={handleChange}>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* repetation */}

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Plan Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_plan_free"
                value={inputs.is_plan_free}
                label="Plan Type"
                disabled={true}
                onChange={handleChange}>
                <MenuItem value={true}>Free</MenuItem>
                <MenuItem value={false}>Paid</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                New User Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_new_user_created_for"
                value={inputs.is_new_user_created_for}
                label="New User Status"
                onChange={handleChange}>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div> */}

          {inputs.is_plan_free == false && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Payment Method
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    // disabled={true}
                    id="demo-simple-select"
                    name="payment_method_preferance"
                    value={inputs.payment_method_preferance}
                    label="Payment Method"
                    onChange={handleChange}>
                    <MenuItem value="online">Online</MenuItem>
                    <MenuItem value="offline">Offline</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Plan Payment
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    disabled={true}
                    id="demo-simple-select"
                    name="payment_access"
                    value={inputs.payment_access}
                    label="Plan Payment"
                    onChange={handleChange}>
                    <MenuItem value="onetime">OneTime</MenuItem>
                    <MenuItem value="recursion">Recurring</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                className={`col-lg-6 col-md-6 col-sm-12 mt-4 ${
                  inputs.payment_access == "recursion" ? "d-none" : "d-block"
                }`}>
                {renderCurrency()}
              </div>

              <div
                className={`col-lg-6 col-md-6 col-sm-12 mt-4 ${
                  inputs.payment_access == "recursion" ? "d-block" : "d-none"
                }`}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label1">
                    Plan Payment Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label1"
                    id="demo-simple-select"
                    name="plan_type"
                    value={inputs.plan_type ? inputs.plan_type : "week"}
                    label="Plan Payment Type"
                    onChange={handleChange}
                    required>
                    <MenuItem value="week">Weekly</MenuItem>
                    <MenuItem value="month">Monthly</MenuItem>
                    <MenuItem value="year">Yearly</MenuItem>
                    <MenuItem value="custom">Custom</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputs.plan_type == "custom" && (
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="No of days"
                    variant="outlined"
                    fullWidth
                    required
                    type="number"
                    name="number_of_days"
                    value={inputs.number_of_days}
                    onChange={handleChange}
                  />
                </div>
              )}
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Total Plan Price"
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  name="plan_price"
                  value={inputs.plan_price}
                  onChange={handleChange}
                />
              </div>
              {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="plan_for">Plan For</InputLabel>
                  <Select
                    labelId="plan_for"
                    id="demo-simple-select"
                    name="plan_for"
                    value={inputs.plan_for}
                    label="Plan For"
                    onChange={handleChange}>
                    <MenuItem value="Basic">Basic</MenuItem>
                    <MenuItem value="Premium">Premium</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
              {/* {inputs.payment_access == "recursion" ? (
                <>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Initial Amount"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="initial_amount"
                      value={inputs.initial_amount}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Recurring Time Period "
                      variant="outlined"
                      fullWidth
                      required
                      type="number"
                      name="time_period_interval"
                      value={inputs.time_period_interval}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Installment Amount"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      aria-readonly
                      name="installment_amount"
                      value={inputs.installment_amount}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Trial Period Days"
                      variant="outlined"
                      fullWidth
                      name="trial_period_days"
                      value={inputs.trial_period_days}
                      type="number"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    {renderCurrency()}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <FormControl fullWidth required>
                      <InputLabel id="plan_for">
                        Is There Difference Between First & Next Recurring ?
                      </InputLabel>
                      <Select
                        labelId="plan_for"
                        id="demo-simple-select"
                        name="first_and_next_recurring_days_diffrence"
                        value={inputs.first_and_next_recurring_days_diffrence}
                        label="Is There Difference Between First & Next Recurring ?"
                        onChange={handleChange}>
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {inputs.first_and_next_recurring_days_diffrence && (
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                      <TextField
                        id="outlined-basic"
                        label="Days Difference"
                        variant="outlined"
                        fullWidth
                        name="days_difference"
                        value={inputs.days_difference}
                        type="number"
                        onChange={handleChange}
                      />
                    </div>
                  )}
                </>
              ) : (
                <></>
              )} */}
            </>
          )}

          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Campaign Tag ID"
              variant="outlined"
              fullWidth
              type="number"
              name="active_campaign_tag_id"
              value={inputs.active_campaign_tag_id}
              onChange={handleChange}
            />
          </div> */}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Campaign List ID"
              variant="outlined"
              fullWidth
              type="number"
              name="active_campaign_list_id"
              value={inputs.active_campaign_list_id}
              onChange={handleChange}
            />
          </div>

          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Maximum Teachers Count"
              variant="outlined"
              fullWidth
              required
              type="number"
              name="max_teacher_limit"
              onWheel={(event) => event.target.blur()}
              value={inputs.max_teacher_limit}
              onChange={handleChange}
            />
          </div> */}
          {/*  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Maximum Subscriber Count"
              variant="outlined"
              fullWidth
              type="number"
              name="subscriber_count"
              value={inputs.subscriber_count}
              onChange={handleChange}
            />
          </div>
          */}
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Don't Show Full Amount ?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_dont_show_full_amount"
                value={inputs.is_dont_show_full_amount}
                label="Don't Show Full Amount ? "
                onChange={handleChange}>
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div> */}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Plan Button Text"
              variant="outlined"
              fullWidth
              name="plan_button_text"
              value={inputs.plan_button_text}
              onChange={handleChange}
            />
          </div>

          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="is_basic_membership">
                Is Basic Membership
              </InputLabel>
              <Select
                labelId="is_basic_membership"
                id="demo-simple-select"
                name="is_basic_membership"
                value={inputs.is_basic_membership}
                label="Is Basic Membership"
                onChange={handleChange}>
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </div> */}
          {formType === "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Plan Order"
                variant="outlined"
                fullWidth
                required
                type="number"
                name="plan_order"
                value={inputs.plan_order}
                onChange={handleChange}
              />
            </div>
          )}
          {inputs.is_plan_free == false && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Stripe Product Name"
                variant="outlined"
                fullWidth
                required
                name="stripe_product_name"
                value={inputs.stripe_product_name}
                onChange={handleChange}
              />
            </div>
          )}

          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Plan Image </p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-3">
                <div className="col-3">
                  {file ? (
                    <img className="image-border" src={file} height="70" />
                  ) : (
                    oldImage && (
                      <img
                        className="image-border"
                        src={s3baseUrl + oldImage}
                        height="70"
                      />
                    )
                  )}
                </div>
              </div>
              <div className="col-4 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="plan_image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span">
                    Upload
                  </Button>
                </label>
              </div>
            </div>
          </div>
          <h4 className="mt-4">Time Slots *</h4>
          <TimeSlots
            fieldsArray={fieldsArray}
            setFieldsArray={setFieldsArray}
          />
          {/* {inputs.is_plan_free == false &&
            inputs.payment_access == "recursion" && (
              <div className="col-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">Product</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="product"
                    value={inputs.product}
                    label="product"
                    onChange={handleChange}>
                    {products.map((product) => {
                      return (
                        <MenuItem value={product._id}>{product.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            )} */}

          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description"
                multiline
                required
                rows={6}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="col-12 mt-4">
            <h4>Activity</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="activity"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Detail Description *</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="detailed_description"
              editorHeight={320}
            />
          </div>

          <div className="col-12 mt-4">
            <h4>Benefit Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="benefit_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Plan Package</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="plan_package"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
