import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { s3baseUrl } from "src/config/config";
import { CircularProgress, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  blog_list_api,
  delete_blog_api,
} from "src/DAL/WebsitePages/websitepages";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles(() => ({
  loadingSection: {
    marginLeft: "45%",
    marginTop: "20%",
    marginBottom: "20%",
    padding: 0,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function BlogList() {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [blogList, setBlogList] = useState([]);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoadingSection, setIsLoadingSection] = useState(false);
  const is_website_pages = window.location.pathname.includes("website-pages");

  let page_name = "/template-pages";
  if (is_website_pages) {
    page_name = "/website-pages";
  }

  const get_blog_list = async () => {
    setIsLoadingSection(true);
    const result = await blog_list_api(params?.id);
    if (result.code == 200) {
      setBlogList(
        result.website_blog.map((blog) => {
          return {
            ...blog,
            table_avatar: {
              src: s3baseUrl + blog.image?.thumbnail_1,
              alt: blog.title,
            },
            table_avatar2: {
              src: s3baseUrl + blog.author_image?.thumbnail_1,
              alt: blog.title,
            },
          };
        })
      );
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingSection(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoadingSection(true);
    const result = await delete_blog_api(deleteDoc.blog_slug);
    if (result.code === 200) {
      get_blog_list();
      setIsLoadingSection(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingSection(false);
    }
  };

  const handleAddCatagories = () => {
    navigate(`${page_name}/blogs-setting/${params?.id}/add-blog`);
  };

  const handleEdit = (value) => {
    navigate(
      `${page_name}/blogs-setting/${params?.id}/edit-blog/${value.blog_slug}`,
      {
        state: value,
      }
    );
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Title" },
    {
      id: "table_avatar",
      label: "Blog Image",
      type: "thumbnail",
    },
    { id: "table_avatar2", label: "Author Image", type: "thumbnail" },

    { id: "order", label: "Order" },
    { id: "status", label: "Stauts", type: "row_status" },
  ];

  useEffect(() => {
    get_blog_list();
  }, []);

  if (isLoadingSection == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="col-12 display-flex mb-4">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-lg-6 col-sm-12 mb-3">
            <h2>Blogs</h2>
          </div>
          <div className="col-lg-6 col-sm-12 mb-3 text-end">
            <button
              className="small-contained-button"
              onClick={handleAddCatagories}
            >
              Add Blog
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={blogList}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
              pagePagination={true}
            />
            <CustomConfirmation
              open={openDelete}
              setOpen={setOpenDelete}
              title={"Are you sure you want to delete ?"}
              handleAgree={handleDelete}
            />
          </div>
        </div>
      </div>
    </>
  );
}
