import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { EditTeachers } from "src/DAL/Teachers/Teachers";

export default function EditTeacher({ onCloseDrawer, dataList, editData }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    name: "",
    email: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(state, "submitting data");
    let postData = {
      name: state.name,
      email: state.email,
    };
    const result = await EditTeachers(editData._id, postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      onCloseDrawer();
      dataList();
    } else {
      //console.log(result, "error case");
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ["name"]: editData?.name,
      ["email"]: editData?.email,
    }));
  }, []);

  return (
    <div className="container new-memories">
      <form onSubmit={handleSubmit}>
        <TextField
          className="mt-4 inputs-fields"
          id="outlined-basic"
          label="Name"
          variant="outlined"
          name="name"
          value={state.name}
          required={true}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          className="mt-4 inputs-fields"
          id="outlined-basic"
          label="Email"
          variant="outlined"
          name="email"
          type="email"
          value={state.email}
          required={true}
          onChange={(e) => handleChange(e)}
        />

        <div className="text-end mt-3">
          <button className="small-contained-button" disabled={isLoading}>
            {isLoading ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
}
