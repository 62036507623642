import {
  Box,
  Grid,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
} from "../components/_dashboard/app";
import DashboardTable from "src/components/_dashboard/DashboardTable";
import RecentMembersTable from "src/components/_dashboard/RecentMembers";
import Page from "src/components/Page";
import AppTotalSales from "src/components/_dashboard/app/AppTotalSale";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import { admin_with_token } from "src/DAL/SiteSetting/siteSetting";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function DashboardApp() {
  const { metaTitle } = usePGIMode();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    member_count: "",
    program_count: "",
    lesson_count: "",
    program_recording_count: "",
    recent_lesson: [],
    recent_member: [],
    events: [],
    total_transaction_amount: "",
  });

  const getProjectInfo = async () => {
    setLoading(true);
    const result = await admin_with_token();
    if (result.code === 200) {
      setInputs(result.dashboard_data);
      localStorage.setItem("favIcon", result.default_setting.admin_favicon);
      localStorage.setItem("adminLogo", result.default_setting.admin_logo);
      localStorage.setItem("metaTitle", result.default_setting.meta_title);
      localStorage.setItem(
        "metaDescription",
        result.default_setting.meta_description
      );
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProjectInfo();
  }, []);

  if (loading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <Page title={metaTitle}>
      <Container maxWidth="xl" className="mt-3">
        <Box sx={{ pb: 5 }}>
          <Typography className="welcome-back" variant="h4">
            Hi, Welcome back
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppTotalSales total={inputs.total_transaction_amount} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers members={inputs.member_count} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders programs={inputs.program_count} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports lesson={inputs.lesson_count} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <RecentMembersTable recentMember={inputs.recent_member} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <DashboardTable recentLesson={inputs.recent_lesson} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
