import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import {
  add_blog_api,
  update_blog_api,
} from "src/DAL/WebsitePages/websitepages";
import { s3baseUrl } from "src/config/config";
import GeneralCkeditor from "src/components/GeneralCkeditor";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddUpdateBlog() {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setProfileImage] = useState();
  const [fileAuthor, setAuthorImage] = useState();
  const [formType, setFormType] = useState("ADD");
  const [oldfile, setoldProfileImage] = useState();
  const [oldAuthor, setoldAuthorImage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [inputs, setInputs] = useState({
    title: "",
    button_text: "",
    button_link: "",
    author_bio: "",
    image: {},
    status: true,
    posted_date: new Date(),
    author_image: {},
    short_description: "",
    detail_description: "",
    order: "",
  });

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
    setoldProfileImage("");
  };

  const fileChangedAuthor = (e) => {
    setAuthorImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["author_image"]: e.target.files[0],
    });
    setoldAuthorImage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("status", inputs.status);
    formData.append("short_description", inputs.short_description);
    if (file) {
      formData.append("image", inputs.image);
    }
    if (fileAuthor) {
      formData.append("author_image", inputs.author_image);
    }
    formData.append("button_text", inputs.button_text);
    formData.append("button_link", inputs.button_link);
    formData.append("author_bio", inputs.author_bio);
    formData.append("detailed_description", inputs.detail_description);
    formData.append(
      "posted_date",
      moment(inputs.posted_date).format("YYYY-MM-DD")
    );
    formData.append("created_for", "sale_page");
    formData.append("page_id", params?.id);

    if (formType === "EDIT") {
      formData.append("order", inputs.order);
    }

    const result =
      formType == "ADD"
        ? await add_blog_api(formData)
        : await update_blog_api(formData, params?.blog_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeDate = (event) => {
    setInputs({
      ...inputs,
      ["posted_date"]: event.$d,
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (params && params.id) {
      if (state) {
        setInputs((values) => ({
          ...values,
          ["title"]: state?.title,
          ["author_name"]: state?.author_name,
          ["author_bio"]: state?.author_bio,
          ["status"]: state?.status,
          ["posted_date"]: state?.posted_date,
          ["short_description"]: state?.short_description,
          ["detail_description"]: state?.detailed_description,
          ["button_text"]: state?.button_text,
          ["button_link"]: state?.button_link,
          ["order"]: state?.order,
        }));
        setoldProfileImage(state?.image?.thumbnail_1);
        setoldAuthorImage(state?.author_image?.thumbnail_1);
        setFormType("EDIT");
      }
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 display-flex mb-4">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>
            <h2>{`${formType === "ADD" ? "Add" : "Edit"} Blog`}</h2>
          </h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Posted Date"
                name="posted_date"
                inputFormat="DD-MM-YYYY"
                value={inputs.posted_date}
                format="DD-MM-YYYY"
                onChange={(e) => handleChangeDate(e)}
                renderInput={(params) => (
                  <TextField {...params} required={true} className="w-100" />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Button Text"
              variant="outlined"
              fullWidth
              required
              name="button_text"
              value={inputs.button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Button Link"
              variant="outlined"
              fullWidth
              name="button_link"
              value={inputs.button_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          {formType !== "ADD" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                required
                name="order"
                value={inputs.order}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Blog Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {oldfile ? (
                  <img
                    className="image-border"
                    src={s3baseUrl + oldfile}
                    height="50"
                  />
                ) : (
                  file && (
                    <img className="image-border" src={file} height="50" />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Author Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {oldAuthor ? (
                  <img
                    className="image-border"
                    src={s3baseUrl + oldAuthor}
                    height="50"
                  />
                ) : (
                  fileAuthor && (
                    <img
                      className="image-border"
                      src={fileAuthor}
                      height="50"
                    />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file2">
                  <Input
                    accept="image/*"
                    id="contained-button-file2"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedAuthor}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.author_image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.author_image?.name}</p>
            )}
          </div>
          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description "
                multiline
                required
                rows={6}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="col-12 mt-4">
            <h4>Detail Description *</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="detail_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Author Bio *</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="author_bio"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
