import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";

import {
  addCalenderSettings,
  getSettings,
  siteSettingApi,
} from "src/DAL/SiteSetting/siteSetting";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CalendarSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const [inputs, setInputs] = React.useState({
    client_id: "",
    target_url: "",
    client_secret: "",
  });
  const siteSettingData = async () => {
    setIsLoading(true);
    const result = await getSettings();
    if (result.code == 200) {
      setInputs({
        ...inputs,
        ["client_id"]: result?.content_setting?.calender_setting?.client_id,
        ["client_secret"]:
          result?.content_setting?.calender_setting?.client_secret,
        ["target_url"]: result?.content_setting?.calender_setting?.target_url,
      });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let postData = {
      calender_setting: {
        client_id: inputs.client_id,
        client_secret: inputs.client_secret,
        target_url: inputs.target_url,
      },
    };
    console.log(postData, "postData");
    setIsLoading(true);
    const result = await addCalenderSettings(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  React.useEffect(() => {
    siteSettingData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12"></div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>Calendar Setting</h2>
          <p>
            This will Allow the Parents to Integrate their Bookings into the
            Google Calendar.
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Client ID"
              variant="outlined"
              fullWidth
              required
              type="client_id"
              name="client_id"
              value={inputs.client_id}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Client Secret Key"
              variant="outlined"
              fullWidth
              required
              name="client_secret"
              value={inputs.client_secret}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Redirect URL"
              variant="outlined"
              type="url"
              fullWidth
              required
              name="target_url"
              value={inputs.target_url}
              onChange={handleChange}
            />
          </div>

          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
