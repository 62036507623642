import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";

// ----------------------------------------------------------------------

export default function PodsListMemberProfile({ pods }) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-7 col-sm-12 mt-5">
          <h2>Pods</h2>
        </div>
        <div className="col-lg-5 col-sm-12 text-end"></div>
        {pods.map((value, index) => {
          return (
            <>
              <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                <div className="card mt-4 pods-cards-shadow cursor h-100">
                  <img
                    src={s3baseUrl + value.room_image.thumbnail_1}
                    className="card-img-top pods-image"
                    alt="Programme"
                  />
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <h3 className="h2-heading">
                          {htmlDecode(value.title)}
                        </h3>
                      </div>
                    </div>
                    <p className="programme-card-desc mb-3">
                      {htmlDecode(value.short_description)}
                    </p>

                    <div className="row recording-card-date">
                      <div className="col-12 card-button recording-card-date-position mb-2">
                        <p className="pods-active-members">
                          <span>
                            <b>Pod Type: </b> {value.room_type}{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
