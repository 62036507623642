import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import {
  ForgetPassword,
  Programmes,
  ProgrammesDetail,
  LessonsDetail,
  LessonListing,
  ClientVideos,
  AdminVideos,
  AddAdminVideos,
  AdminVideoDetail,
  AddClientVideos,
  ClientVideoDetail,
  EditAdminVideos,
  EditClientVideos,
} from "./pages/index";
import Login from "./pages/Login";
import DashboardApp from "./pages/DashboardApp";
import NotFound from "./pages/Page404";
import AddProgramme from "./pages/Programmes/AddProgramme";
import EditProgramme from "./pages/Programmes/EditProgramme";
import AddLesson from "./pages/Programmes/AddLesson";
import EditLesson from "./pages/Programmes/EditLesson";
import LessonRecordingListing from "./pages/LessonRecording/LessonRecordingListing";
import LessonRecordingDetail from "./pages/LessonRecording/LessonRecordingDetail";
import AddLessonRecording from "./pages/LessonRecording/AddLessonRecording";
import EditLessonRecording from "./pages/LessonRecording/EditLessonRecording";
import { ResourcesCard } from "./pages/LessonRecording/ResourcesCard";
import AddLessonDocument from "./pages/LessonRecording/AddLessonDocument";
import EditLessonDocument from "./pages/LessonRecording/EditLessonDocument";
import { ProgrammeDocumentList } from "./pages/Programmes/ProgrammeDocumentList";
import AddProgrammeDocument from "./pages/Programmes/AddProgramDocument";
import EditProgrammeDocument from "./pages/Programmes/EditProgrammeDocument";
import { SectionList } from "./pages/section/SectionList";
import ProgrammeReviews from "./pages/ProgramReviews/ProgrammeReviews";
import AddProgrammeReview from "./pages/ProgramReviews/AddProgramReview";
import EditProgrammeReview from "./pages/ProgramReviews/EditProgramReview";
import ProgrammeSectionList from "./pages/Sections/SectionList";
import LockedProgram from "./pages/Programmes/LockedProgramInfo";
import AddProgrammeSection from "./pages/Sections/AddProgrammeSection";
import EditProgrammeSection from "./pages/Sections/EditProgrammeSection";
import ProgrammeAutomatedGroups from "./pages/AutomatedGroups/ProgrammeAutomatedGroup";
import AddProgrammeAutoGroup from "./pages/AutomatedGroups/AddAutoGroup";
import EditProgrammeAutoGroup from "./pages/AutomatedGroups/EditAutoGroup";
import MembersList from "./pages/Members/MembersList";
import AddMember from "./pages/Members/AddMember";
import EditMember from "./pages/Members/EditMember";
import ChangePassword from "./pages/Members/ChangePassword";
import MemberProfile from "./pages/Members/MemberProfile";
import MemberAllTransaction from "./pages/Members/AllTransactions";
import ViewBillingList from "./pages/Members/ViewBilling";
import BillingRequestDetail from "./pages/Members/BillingDetail";
import AddPaymentRequest from "./pages/Members/AddPaymentRequest";
import ManageProgrammeAccess from "./pages/Members/ManageProgrammeAccess";
import MemberGroupsList from "./pages/Members/MembersGroupList";
import MemberPersonalNote from "./pages/Members/MemberPersonalNote";
import MemberGoalstatementList from "./pages/Members/MemberGoalstatementList";
import PodsListMemberProfile from "./pages/Members/PodsListMemberProfile";
import { QuestionsReply } from "./pages/Members/QuestionsReply";
import { AnswerHistory } from "./pages/Members/AnswerHistory";
import EditRecording from "./pages/Recordings/EditRecording";
import PaymentTransactionsList from "./pages/Payments/TransactionsList";
import StripeProductsList from "./pages/Payments/StripeProductsList";
import AddStripeProduct from "./pages/Payments/AddStripeProduct";
import EditStripeProduct from "./pages/Payments/EditStripeProduct";
import PaymentTemplateList from "./pages/PaymentTemplate/PaymentTemplateList";
import PaymentRequestList from "./pages/PaymentRequest/PaymentRequestList";
import AddCalendarGroup from "./pages/CalendarGroups/AddCalendarGroups";
import CalendarGroupsList from "./pages/CalendarGroups/CalendarGroupList";
import EditCalendarGroup from "./pages/CalendarGroups/EditCalendarGroups";
import CalendarGroupDetail from "./pages/CalendarGroups/CalendarGroupDetail";
import CalendarPodsList from "./pages/CalendarPods/CalendarPodsList";
import AddCalendarPods from "./pages/CalendarPods/AddPods";
import EditCalendarPods from "./pages/CalendarPods/EditPods";
import CalendarPodsDetail from "./pages/CalendarPods/CalendarPodsDetail";
import AddAutomatedRoomUser from "./pages/CalendarPods/AddRoomUser";
import EditAutomatedRoomUser from "./pages/CalendarPods/EditRoomUser";
import Calenders from "./pages/Calender/Calender";
import ListEvents from "./pages/Calender/ListEvents";
import { SupportTicket } from "./pages/index";
import UpdateGeneralSetting from "./pages/ClientSetting/GeneralSetting";
import ClientDashboardSetting from "./pages/ClientSetting/ClientDashboard";
import UpdateContentSetting from "./pages/ClientSetting/ContentSetting";
import AddCompaignSetting from "./pages/SiteSetting/CompaingSetting";
import AddStripeSetting from "./pages/SiteSetting/StripeSetting";
import EmailSetting from "./pages/SiteSetting/EmailSetting";
import DefaultSetting from "./pages/SiteSetting/DefaultSetting";
import EmailTemplates from "./pages/SiteSetting/EmailTemplates";
import EditEmailTemplate from "./pages/SiteSetting/EditEmailTemplate";
import { EmailTemplatePreview } from "./pages/SiteSetting/EmailTemplatePreview";
import ToDoTracker from "./pages/Members/ToDoTracker";
import UpdatePageContent from "./pages/WebPages/WebsitePages/UpdatePageContent";
import EditPaymentPlan from "./pages/PaymentPlans/EditpaymentPlan";
import EditProfile from "./pages/EditProfile/EditProfile";
import ImportMembers from "./pages/Members/ImportMember";
import ManageRewardAccess from "./pages/Members/ManageRewardAccess";
import MemoryHistory from "./pages/Members/MemberHistory";
import AddWebPageEvent from "./pages/WebPageEvents/AddWebPageEvent";
import EditWebPageEvent from "./pages/WebPageEvents/EditWebPageEvent";
import WebPageEvents from "./pages/WebPageEvents/WebPageEvents";
import WebsiteProgrammesList from "./pages/WebPages/WebsiteProgrammes/WebsiteProgrammesList";
import AddWebsiteProgramme from "./pages/WebPages/WebsiteProgrammes/AddOrUpdateProgramme";
import EventSubscribers from "./pages/Members/EventSubscriber";
import { QuestionSurvey } from "./pages/QuestionSurvey/QuestionSurvey";
import WhatsIncludeList from "./pages/WhatsInclude/WhatsIncludeList";
import AddWhatsInclude from "./pages/WhatsInclude/AddWhatsInclude";
import EditWhatsInclude from "./pages/WhatsInclude/EditWhatsInclude";
import SocialSharingCenter from "./pages/ManageWebiste/soicalSharingCenter";
import TemplatePages from "./pages/WebPages/TemplatePages/TemplatePages";
import SocialSharingSetting from "./pages/WebPages/TemplatePages/SoicalSharingSetting";
import PageSubscribersList from "./pages/WebPages/TemplatePages/PageSubscribersList";
import AddOrUpdatePage from "./pages/WebPages/WebsitePages/AddOrUpdatePage";
import UpdateThanksContent from "./pages/WebPages/WebsitePages/UpdateThanksContent";
import AddOrUpdateWebPageTestimonial from "./pages/WebPages/WebPageTestimonials/AddOrUpdateWebPageTestimonial";
import WebPageFaqs from "./pages/WebPages/WebPageFaqs/WebPageFaqs";
import AddOrUpdateWebPageFaq from "./pages/WebPages/WebPageFaqs/AddOrUpdateWebPageFaq";
import MenuList from "./pages/WebPages/WebsitePages/MenuItems/MenuList";
import { MenuItems } from "./pages/WebPages/WebsitePages/MenuItems/MenuItems";
import WebPageTestimonials from "./pages/WebPages/WebPageTestimonials/WebPageTestimonials";
import WebPagePaymentPlans from "./pages/WebPages/WebPagePaymentPlans/WebPagePaymentPlans";
import AddOrUpdatePaymentPlan from "./pages/WebPages/WebPagePaymentPlans/AddOrUpdatePaymentPlan";
import BusinessStrategyList from "./pages/WebPages/BusinessStrategy/BusinessStrategyList";
import AddOrUpdateBusinessStrategy from "./pages/WebPages/BusinessStrategy/AddOrUpdateBusinessStrategy";
import AddBusinessStrategy from "./pages/WebPages/BusinessStrategy/AddOrUpdateBusinessStrategy";
import SuccessScheduleList from "./pages/WebPages/SuccessSchedule/successScheduleList";
import AddOrUpdateSuccessSchedule from "./pages/WebPages/SuccessSchedule/AddOrUpdateSuccessSchedule";
import UnpublishSetting from "./pages/WebPages/WebsitePages/UnpublishSetting";
import BannerSliderList from "./pages/WebPages/BannerSlider/BannerSliderList";
import AddBannerSlider from "./pages/WebPages/BannerSlider/AddBannerSlider";
import InvoiceSetting from "./components/InvoiceSetting/InvoiceSetting";
import ProgrammeBulkAccess from "./components/ProgrammBulkAccess/ProgrammeBulkAccess";
import SubscriptionList from "./pages/SubscriptionList/SubscriptionList";
import ProgrammeMembers from "./pages/Programmes/ProgrammeMembers";
import MemberSubscriptions from "./pages/Members/MemberSubscriptions/MemberSubscriptions";
import PagePlanSubscribersList from "./pages/WebPages/WebPagePaymentPlans/PagePlanSubscribersList";
import ProgrammeAccessPaymentPlan from "./pages/WebPages/WebPagePaymentPlans/ProgrammeAccessPaymentPlan";
import EventAccessPaymentPlan from "./pages/WebPages/WebPagePaymentPlans/EventAccessPaymentPlan";
import CatagoriesList from "./pages/Catagories/CatagoriesList";
import AddOrCatagories from "./pages/Catagories/AddOrCatagories";
import BlogList from "./pages/Blog/BlogList";
import AddUpdateBlog from "./pages/Blog/AddUpdateBlog";
import AddPaymentTemplate from "./pages/PaymentTemplate/AddPaymentTemplate";
import AddPaymentRequests from "./pages/PaymentRequest/AddPaymentRequests";
import PaymentRequestInfo from "./pages/PaymentRequest/PaymentRequestInfo";
import EditPaymentRequests from "./pages/PaymentRequest/EditPaymentRequests";
import EditPaymentTemplate from "./pages/PaymentTemplate/EditPaymentTemplate";
import ConsultantGroupDetail from "./pages/consultant/ConsultantGroupDetail";
import EventDetailList from "./pages/Calender/EventDetailList";
import SupportTicketDetail from "./pages/SupportTickets/DetailSupportTicket";
import CatagoryessonListing from "./pages/Catagories/CatagoryessonListing";
import CatagoryAddLesson from "./pages/Catagories/CatagoryAddLesson";
import CatagoryLessonsDetail from "./pages/Catagories/CatagoryLessonsDetail";
import CatagoryEditLesson from "./pages/Catagories/CatagoryEditLesson";
import { CatagoryResourcesCard } from "./pages/Catagories/CatagoryResourcesCard";
import Teachers from "./pages/Teachers/Teachers";
import BookingPaymentPlans from "./pages/WebPages/WebPagePaymentPlans/BookingPaymentPlans";
import AddOrUpdateBookingPaymentPlan from "./pages/WebPages/WebPagePaymentPlans/AddOrUpdateBookingPaymentPlan";
import FormTemplates from "./pages/WebPages/WebsitePages/FormTemplates";
import AddFormTemplate from "./pages/WebPages/WebsitePages/AddFormTemplate";
import DetailsFormInformation from "./components/FormTemplates/DetailsFormInformation";
import FormTemplateEdit from "./pages/WebPages/WebsitePages/FormTemplateEdit";
import TransactionDetail from "./pages/Payments/TransactionDetail";
import Bookings from "./pages/Payments/Bookings";
import BookingPagePlanSubscribersList from "./pages/WebPages/WebPagePaymentPlans/BookingPagePlanSubscribersList";
import CalendarSetting from "./pages/SiteSetting/CalendarSetting";
import WebPagePDF from "./pages/WebPages/PdfModule/WebPagePDF";
import AddOrUpdateWebPagePdf from "./pages/WebPages/PdfModule/AddOrUpdateWebPagePdf";

// ----------------------------------------------------------------------
const Authentication = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/dashboard"> </Navigate>;
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
};
export default function Router() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/dashboard" element={<DashboardApp />} />
        <Route path="/programmes" element={<Programmes />} />
        <Route
          path="/programmes/programmes-detail/:id"
          element={<ProgrammesDetail />}
        />
        <Route path="/programmes/members/:id" element={<ProgrammeMembers />} />
        <Route path="/programmes/add-programme" element={<AddProgramme />} />
        <Route
          path="/programmes/edit-programme/:programme_slug"
          element={<EditProgramme />}
        />
        <Route
          path="/programmes/lessons-listing/:slug"
          element={<LessonListing />}
        />
        <Route
          path="/programmes/lessons-detail/:id"
          element={<LessonsDetail />}
        />
        <Route path="/programmes/add-lessons" element={<AddLesson />} />
        <Route path="/programmes/edit-lessons/:id" element={<EditLesson />} />
        <Route
          path="/programmes/lessons/recordings/:slug"
          element={<LessonRecordingListing />}
        />
        <Route
          path="/programmes/lesson-recording-detail/:slug"
          element={<LessonRecordingDetail />}
        />
        <Route
          path="/programmes/add-lesson-recording/:slug"
          element={<AddLessonRecording />}
        />
        <Route
          path="/programmes/edit-lessons-recording/:slug"
          element={<EditLessonRecording />}
        />
        <Route
          path="/programmes/lesson/lesson-doc/:slug"
          element={<ResourcesCard />}
        />
        <Route
          path="/programmes/lessons/add-lesson-document/:slug"
          element={<AddLessonDocument />}
        />
        <Route
          path="/programmes/lessons/edit-lesson-document/:slug"
          element={<EditLessonDocument />}
        />
        <Route path="/programmes/:type/:id" element={<ProgrammeBulkAccess />} />
        <Route
          path="/programmes/programm-resources/:id"
          element={<ProgrammeDocumentList />}
        />
        <Route
          path="/programmes/add-programme-resource/:slug"
          element={<AddProgrammeDocument />}
        />
        <Route
          path="/programmes/edit-programme-resource/:slug"
          element={<EditProgrammeDocument />}
        />
        <Route path="/programmes/sections/:slug" element={<SectionList />} />
        <Route
          path="/programmes/reviews/:slug"
          element={<ProgrammeReviews />}
        />
        <Route
          path="/programmes/add-reviews/:slug"
          element={<AddProgrammeReview />}
        />
        <Route
          path="/programmes/edit-review/:slug"
          element={<EditProgrammeReview />}
        />
        <Route
          path="/programmes/section/:slug"
          element={<ProgrammeSectionList />}
        />
        <Route
          path="/programmes/locked-programme/:slug"
          element={<LockedProgram />}
        />
        <Route
          path="/programmes/add-section/:slug"
          element={<AddProgrammeSection />}
        />
        <Route
          path="/programmes/edit-section/:slug"
          element={<EditProgrammeSection />}
        />
        <Route
          path="/programmes/automated-group/:slug"
          element={<ProgrammeAutomatedGroups />}
        />
        <Route
          path="/programmes/add-automated-group/:slug"
          element={<AddProgrammeAutoGroup />}
        />
        <Route
          path="/programmes/edit-automated-group/:slug"
          element={<EditProgrammeAutoGroup />}
        />
        <Route path="/catagory" element={<CatagoriesList />} />
        <Route
          path="/catagory/catagory-lessons-list/:catagory_id"
          element={<CatagoryessonListing />}
        />
        <Route path="/catagory/add-lesson" element={<CatagoryAddLesson />} />
        <Route
          path="/catagory/catagory-lessons-detail/:detail_slug"
          element={<CatagoryLessonsDetail />}
        />
        <Route
          path="/catagory/edit-lesson/:detail_slug"
          element={<CatagoryEditLesson />}
        />
        <Route
          path="/catagory/lesson/lesson-doc/:slug"
          element={<CatagoryResourcesCard />}
        />
        <Route
          path="/catagory/lessons/add-lesson-document/:slug"
          element={<AddLessonDocument />}
        />
        <Route
          path="/catagory/lessons/edit-lesson-document/:slug"
          element={<EditLessonDocument />}
        />
        <Route path="/catagory/add-catagory" element={<AddOrCatagories />} />
        <Route
          path="/catagory/edit-catagory/:id"
          element={<AddOrCatagories />}
        />
        <Route path="/member/import-member" element={<ImportMembers />} />
        <Route path="/member/add-member" element={<AddMember />} />
        <Route path="/member/edit-member/:id" element={<EditMember />} />
        <Route path="/member/member-profile/:id" element={<MemberProfile />} />
        <Route path="/member/member-history/:id" element={<MemoryHistory />} />
        <Route
          path="/member/member-groups/:id"
          element={<MemberGroupsList />}
        />
        <Route
          path="/member/member-subscribers/:member_id"
          element={<MemberSubscriptions />}
        />
        <Route
          path="/member/member-personal-notes/:id"
          element={<MemberPersonalNote />}
        />
        <Route path="/member/teachers/:id" element={<Teachers />} />
        <Route
          path="/member/member-goal-statement/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/member/goal-statement-question-reply/:id"
          element={<QuestionsReply />}
        />
        <Route
          path="/member/event-subscriber/:id"
          element={<EventSubscribers />}
        />
        <Route
          path="/member/goal-statement-Question-history/:id"
          element={<AnswerHistory />}
        />
        <Route
          path="/member/programme-access/:id"
          element={<ManageProgrammeAccess />}
        />
        <Route
          path="/member/reward-access/:id"
          element={<ManageRewardAccess />}
        />
        <Route
          path="/member/add-payment-request/:id"
          element={<AddPaymentRequest />}
        />
        <Route
          path="/member/billing-detail"
          element={<BillingRequestDetail />}
        />
        <Route
          path="/member/all-transaction/:id"
          element={<MemberAllTransaction />}
        />
        <Route
          path="/member/view-billing-list/:id"
          element={<ViewBillingList />}
        />
        <Route
          path="/member/change-password/:id"
          element={<ChangePassword />}
        />
        <Route path="/member/member-pods" element={<PodsListMemberProfile />} />
        <Route path="/member/to-do-tracker/:id" element={<ToDoTracker />} />
        <Route path="/member" element={<MembersList />} />
        <Route path="/stripe-products" element={<StripeProductsList />} />
        <Route
          path="/stripe-products/add-product"
          element={<AddStripeProduct />}
        />
        <Route
          path="/stripe-products/edit-product/:id"
          element={<EditStripeProduct />}
        />
        <Route
          path="/payment-transactions"
          element={<PaymentTransactionsList />}
        />{" "}
        <Route path="/bookings" element={<Bookings />} />
        <Route
          path="/payment-transactions/transactions-detail/:id"
          element={<TransactionDetail />}
        />
        <Route
          path="/bookings/booking-detail/:id"
          element={<TransactionDetail />}
        />
        <Route path="/booking-subscriber/:id" element={<TransactionDetail />} />
        <Route
          path="/template-pages/booking-subscriber/:id"
          element={<TransactionDetail />}
        />
        <Route
          path="/website-pages/booking-subscriber/:id"
          element={<TransactionDetail />}
        />
        <Route path="/payment-templates" element={<PaymentTemplateList />} />
        <Route
          path="/payment-template/add-template"
          element={<AddPaymentTemplate />}
        />
        <Route
          path="/payment-template/edit-template/:id"
          element={<EditPaymentTemplate />}
        />
        <Route path="/payment-requests" element={<PaymentRequestList />} />
        <Route
          path="/payment-request/add-payment-request"
          element={<AddPaymentRequests />}
        />
        <Route
          path="/payment-request/payment-request-detail"
          element={<PaymentRequestInfo />}
        />
        <Route
          path="/payment-request/edit-payment-request/:id"
          element={<EditPaymentRequests />}
        />
        <Route path="/subscription-list" element={<SubscriptionList />} />
        {/* Calendar    AddCalendarGroup*/}
        <Route path="/calendar-groups" element={<CalendarGroupsList />} />
        <Route
          path="/calendar-groups/group-detail/:id"
          element={<CalendarGroupDetail />}
        />
        <Route
          path="/calendar-groups/view-group-detail/:id"
          element={<ConsultantGroupDetail />}
        />
        <Route
          path="/calendar-groups/add-group"
          element={<AddCalendarGroup />}
        />
        <Route
          path="/calendar-groups/edit-group/:id"
          element={<EditCalendarGroup />}
        />
        <Route path="/calendarEvents" element={<Calenders />} />
        <Route path="/calendarEvents/event-list" element={<ListEvents />} />
        <Route path="/calendar/event-detail" element={<EventDetailList />} />
        {/*Calendar end  */}
        {/*Calendarpods  */}
        <Route path="/calendar-pods/add-pod" element={<AddCalendarPods />} />
        <Route path="/calendar-pods" element={<CalendarPodsList />} />
        <Route
          path="/calendar-pods/edit-pod/:id"
          element={<EditCalendarPods />}
        />
        <Route
          path="/calendar-pods/pods-detail/:id"
          element={<CalendarPodsDetail />}
        />
        <Route
          path="/calendar-pods/add-room-user/:id"
          element={<AddAutomatedRoomUser />}
        />
        <Route
          path="/calendar-pods/edit-room-user/:id"
          element={<EditAutomatedRoomUser />}
        />
        <Route path="/support-tickets" element={<SupportTicket />} />
        <Route
          path="/support-ticket/detail/:id"
          element={<SupportTicketDetail />}
        />
        {/* /general-setting */}
        <Route path="/general-setting" element={<UpdateGeneralSetting />} />
        <Route path="/client-dashboard" element={<ClientDashboardSetting />} />
        <Route path="/content-setting" element={<UpdateContentSetting />} />
        <Route path="/default-setting" element={<DefaultSetting />} />
        <Route path="/stripe-setting" element={<AddStripeSetting />} />
        <Route path="/email-setting" element={<EmailSetting />} />
        <Route path="/campaign-setting" element={<AddCompaignSetting />} />
        <Route path="/calendar-setting" element={<CalendarSetting />} />
        <Route path="/invoice-setting" element={<InvoiceSetting />} />
        <Route path="/email-templates" element={<EmailTemplates />} />
        <Route
          path="/email-templates/preview/:id"
          element={<EmailTemplatePreview />}
        />
        <Route
          path="/email-templates/edit-temail-emplate/:id"
          element={<EditEmailTemplate />}
        />
        {/* pages */}
        <Route path="/website-pages/blogs-setting/:id" element={<BlogList />} />
        <Route
          path="/website-pages/blogs-setting/:id/add-blog"
          element={<AddUpdateBlog />}
        />
        <Route
          path="/website-pages/blogs-setting/:id/edit-blog/:blog_id"
          element={<AddUpdateBlog />}
        />
        <Route
          path="/website-pages/whats-include/:id"
          element={<WhatsIncludeList />}
        />
        <Route
          path="/website-pages/whats-include/add-whats-include/:id"
          element={<AddWhatsInclude />}
        />
        <Route
          path="/website-pages/whats-include/edit-whats-include/:id"
          element={<EditWhatsInclude />}
        />
        <Route
          path="/website-pages/:page_id/business-strategy"
          element={<BusinessStrategyList />}
        />
        <Route
          path="/website-pages/:page_id/business-strategy/add-business-strategy"
          element={<AddOrUpdateBusinessStrategy />}
        />
        <Route
          path="/website-pages/:page_id/business-strategy/edit-business-strategy/:strategy_id"
          element={<AddOrUpdateBusinessStrategy />}
        />
        <Route path="/website-pages" element={<TemplatePages />} />
        <Route path="/form-templates" element={<FormTemplates />} />
        <Route
          path="/form-templates/add-template"
          element={<AddFormTemplate />}
        />{" "}
        <Route
          path="/form-templates/preview-template/:id"
          element={<DetailsFormInformation />}
        />{" "}
        <Route
          path="/form-templates/edit-template/:id"
          element={<FormTemplateEdit />}
        />
        <Route
          path="/website-pages/:page_id/testimonials/add-testimonial"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/website-pages/:page_id/policy-service/add-policy-service"
          element={<AddOrUpdateWebPagePdf />}
        />
        <Route
          path="/website-pages/:page_id/website-faq"
          element={<WebPageFaqs />}
        />
        <Route
          path="/website-pages/:page_id/website-faq/add-faq"
          element={<AddOrUpdateWebPageFaq />}
        />
        <Route
          path="/website-pages/social-sharing-setting/:page_id"
          element={<SocialSharingSetting />}
        />
        <Route
          path="/website-pages/page-subscriber/:id"
          element={<PageSubscribersList />}
        />
        <Route path="/website-pages/add-page" element={<AddOrUpdatePage />} />
        <Route
          path="/website-pages/edit-page/:page_slug"
          element={<AddOrUpdatePage />}
        />
        <Route
          path="/website-pages/update-page-content/:id"
          element={<UpdatePageContent />}
        />
        <Route
          path="/website-pages/update-thanks-content/:id"
          element={<UpdateThanksContent />}
        />
        <Route
          path="/website-pages/:page_id/testimonials"
          element={<WebPageTestimonials />}
        />
        <Route
          path="/website-pages/:page_id/policy-service"
          element={<WebPagePDF />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/"
          element={<WebPagePaymentPlans />}
        />{" "}
        <Route
          path="/website-pages/:page_id/booking-payment-plans/"
          element={<BookingPaymentPlans />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/add-plan"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/booking-payment-plans/add-plan"
          element={<AddOrUpdateBookingPaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/subscriber/:plan_id"
          element={<PagePlanSubscribersList />}
        />
        <Route
          path="/website-pages/:page_id/booking-subscriber/:plan_id"
          element={<BookingPagePlanSubscribersList />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/edit-plan/:plan_id"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/booking-payment-plans/edit-plan/:plan_id"
          element={<AddOrUpdateBookingPaymentPlan />}
        />
        <Route
          path="/website-pages/unpublish-setting/:id"
          element={<UnpublishSetting />}
        />
        <Route
          path="/website-pages/:page_id/testimonials/edit-testimonial/:testimonial_id"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/website-pages/:page_id/policy-service/edit-policy-service/:policy_id"
          element={<AddOrUpdateWebPagePdf />}
        />
        <Route
          path="/website-pages/:page_id/website-faq"
          element={<WebPageFaqs />}
        />
        <Route
          path="/website-pages/:page_id/subscriber/:plan_id"
          element={<PagePlanSubscribersList />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/:plan_id/manage-program-access"
          element={<ProgrammeAccessPaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/:plan_id/manage-events-access"
          element={<EventAccessPaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/questions"
          element={<QuestionSurvey />}
        />
        <Route
          path="/website-pages/:page_id/website-programmes"
          element={<WebsiteProgrammesList />}
        />
        <Route
          path="/website-pages/:page_id/website-programmes/add-programme"
          element={<AddWebsiteProgramme />}
        />
        <Route
          path="/website-pages/:page_id/website-programmes/edit-programme/:programme_slug"
          element={<AddWebsiteProgramme />}
        />
        <Route
          path="/website-pages/sale-page/:page_id"
          element={<WebPageEvents />}
        />
        <Route
          path="/website-pages/add-page-event/:page_id"
          element={<AddWebPageEvent />}
        />
        <Route
          path="/website-pages/:page_id/edit-page-event/:id"
          element={<EditWebPageEvent />}
        />
        <Route
          path="/website-pages/:page_id/banner-slider"
          element={<BannerSliderList />}
        />
        <Route
          path="/website-pages/:page_id/add-banner-slider"
          element={<AddBannerSlider />}
        />
        <Route
          path="/website-pages/:page_id/edit-banner-slider/:banner_id"
          element={<AddBannerSlider />}
        />
        <Route
          path="/website-pages/payment-plans/edit-payment-plan/:id"
          element={<EditPaymentPlan />}
        />
        <Route
          path="/template-pages/edit-page/:page_slug"
          element={<AddOrUpdatePage />}
        />
        <Route
          path="/template-pages/:page_id/testimonials/add-testimonial"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans"
          element={<WebPagePaymentPlans />}
        />
        <Route
          path="/template-pages/:page_id/booking-payment-plans"
          element={<BookingPaymentPlans />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/add-plan"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/booking-payment-plans/add-plan"
          element={<AddOrUpdateBookingPaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/edit-plan/:plan_id"
          element={<AddOrUpdatePaymentPlan />}
        />{" "}
        <Route
          path="/template-pages/:page_id/booking-payment-plans/edit-plan/:plan_id"
          element={<AddOrUpdateBookingPaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/business-strategy"
          element={<BusinessStrategyList />}
        />
        <Route
          path="/template-pages/:page_id/business-strategy/add-business-strategy"
          element={<AddBusinessStrategy />}
        />
        <Route
          path="/template-pages/:page_id/business-strategy/edit-business-strategy/:strategy_id"
          element={<AddBusinessStrategy />}
        />
        <Route
          path="/template-pages/:page_id/success-schedule"
          element={<SuccessScheduleList />}
        />
        <Route
          path="/template-pages/:page_id/add-success-schedule"
          element={<AddOrUpdateSuccessSchedule />}
        />
        <Route
          path="/template-pages/:page_id/edit-success-schedule/:success_id"
          element={<AddOrUpdateSuccessSchedule />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans"
          element={<WebPagePaymentPlans />}
        />
        <Route
          path="/template-pages/social-sharing-setting/:page_id"
          element={<SocialSharingSetting />}
        />
        <Route
          path="/template-pages/update-thanks-content/:id"
          element={<UpdateThanksContent />}
        />
        <Route
          path="/template-pages/update-page-content/:id"
          element={<UpdatePageContent />}
        />
        <Route
          path="/template-pages/page-subscriber/:id"
          element={<PageSubscribersList />}
        />
        <Route path="/template-pages" element={<TemplatePages />} />
        <Route
          path="/template-pages/blogs-setting/:id"
          element={<BlogList />}
        />
        <Route
          path="/template-pages/blogs-setting/:id/add-blog"
          element={<AddUpdateBlog />}
        />
        <Route
          path="/template-pages/blogs-setting/:id/edit-blog/:blog_id"
          element={<AddUpdateBlog />}
        />
        <Route
          path="/template-pages/socail-sharing-setting/:page_slug"
          element={<SocialSharingCenter />}
        />
        <Route
          path="/template-pages/:page_id/testimonials/add-testimonial"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/template-pages/:page_id/testimonials/edit-testimonial/:testimonial_id"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/template-pages/:page_id/policy-service/add-policy-service"
          element={<AddOrUpdateWebPagePdf />}
        />
        <Route
          path="/template-pages/:page_id/policy-service/edit-policy-service/:policy_id"
          element={<AddOrUpdateWebPagePdf />}
        />
        <Route
          path="/template-pages/:page_id/testimonials"
          element={<WebPageTestimonials />}
        />
        <Route
          path="/template-pages/:page_id/policy-service"
          element={<WebPagePDF />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans"
          element={<WebPagePaymentPlans />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/add-plan"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/edit-plan/:plan_id"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/website-programmes"
          element={<WebsiteProgrammesList />}
        />
        <Route
          path="/template-pages/:page_id/website-programmes/add-programme"
          element={<AddWebsiteProgramme />}
        />
        <Route
          path="/template-pages/:page_id/website-programmes/edit-programme/:programme_slug"
          element={<AddWebsiteProgramme />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/:plan_id/manage-events-access"
          element={<EventAccessPaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/subscriber/:plan_id"
          element={<PagePlanSubscribersList />}
        />{" "}
        <Route
          path="/template-pages/:page_id/booking-subscriber/:plan_id"
          element={<BookingPagePlanSubscribersList />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/:plan_id/manage-program-access"
          element={<ProgrammeAccessPaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/banner-slider"
          element={<BannerSliderList />}
        />
        <Route
          path="/template-pages/:page_id/add-banner-slider"
          element={<AddBannerSlider />}
        />
        <Route
          path="/template-pages/:page_id/edit-banner-slider/:banner_id"
          element={<AddBannerSlider />}
        />
        <Route
          path="/recording-list/edit-recording"
          element={<EditRecording />}
        />
        <Route path="/menus" element={<MenuList />} />
        <Route path="/menu/add-menu" element={<MenuItems />} />
        <Route path="/menu/:id" element={<MenuItems />} />
        <Route path="/admin-videos" element={<AdminVideos />} />
        <Route
          path="/admin-videos/add-admin-videos"
          element={<AddAdminVideos />}
        />
        <Route
          path="/admin-videos/edit-admin-videos"
          element={<EditAdminVideos />}
        />
        <Route
          path="/admin-videos/admin-videos-detail/:id"
          element={<AdminVideoDetail />}
        />
        <Route
          path="/client-portal-videos/edit-client-videos"
          element={<EditClientVideos />}
        />
        <Route path="/client-portal-videos" element={<ClientVideos />} />
        <Route
          path="/client-portal-videos/add-client-videos"
          element={<AddClientVideos />}
        />
        <Route
          path="/client-portal-videos/client-videos-detail/:id"
          element={<ClientVideoDetail />}
        />
        <Route path="/profile" element={<EditProfile />} />
        {/* pages end */}
      </Route>
      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="404" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget_password" element={<ForgetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
