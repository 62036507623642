import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  TextField,
} from "@mui/material";
import { get_root_value } from "src/utils/domUtils";

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: get_root_value("--input-text-color"),
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active, openDra }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: theme.palette.primary.main,
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": { display: "block" },
  };

  const activeSubStyle = {
    color: theme.palette.primary.main,
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  };

  useEffect(() => {
    setOpen(openDra);
  }, [openDra]);

  useEffect(() => {
    if (item?.children)
      item?.children.map((child_option) => {
        if (window.location.pathname.includes(child_option?.path)) {
          setOpen(true);
        }
      });
  }, [window.location.pathname, !openDra]);
  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path, icon } = item;
              const isActiveSub = active(path);
              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),

                    pl: 8,
                  }}
                >
                  <ListItemIconStyle>{icon}</ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle
        sx={{
          ...(isActiveRoot && activeRootStyle),
        }}
      >
        {icon && icon}
      </ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [openDropdown, setOpenDropdown] = useState(false);
  const [openSession, setOpenSession] = useState(false);
  const [openExtras, setOpenExtras] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openSupport, setOpenSupport] = useState(false);
  const [openClient, setOpenClient] = useState(false);
  const [openConsultant, setOpenConsultant] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [openWebsiteSetting, setOpenWebsiteSetting] = useState(false);
  const [openHelpingVideos, setOpenHelpingVideos] = useState(false);

  const matchSideBar = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  const findItemsByQuery = (query) => {
    const matchingItems = navConfig
      .map((item) => {
        const matchedChildren = item?.children
          ? item?.children.filter((child) =>
              child.title.toLowerCase().includes(query.toLowerCase())
            )
          : "";
        if (
          item?.title.toLowerCase().includes(query.toLowerCase()) ||
          matchedChildren.length > 0
        ) {
          return {
            ...item,
            children: matchedChildren,
          };
        }
        return null;
      })
      .filter(Boolean);

    return matchingItems;
  };

  const [query, setQuery] = useState("");
  const matchingItems = findItemsByQuery(query);

  return (
    <Box {...other}>
      <div className="text-center">
        <TextField
          size="small"
          id="outlined-basic"
          label=""
          variant="outlined"
          autoComplete="off"
          sx={{
            width: "85%",

            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
            mb: 1,
          }}
          required
          name="question_statement"
          placeholder="Search..."
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
      </div>
      <List disablePadding sx={{ mb: 6 }}>
        {matchingItems.map((item) => (
          <NavItem
            key={item.title}
            item={item}
            active={matchSideBar}
            openDra={query ? true : false}
          />
        ))}
      </List>
    </Box>
  );
}
