import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
  CircularProgress,
  Pagination,
} from "@mui/material";
// components
import SearchNotFound from "../../components/SearchNotFound";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import {
  UserListHead,
  UserListToolbar,
} from "../../components/_dashboard/user";
//
import { clientPortalUrl, s3baseUrl } from "../../config/config";
import { useSnackbar } from "notistack";
import moment from "moment";
import Label from "src/components/Label";
import { makeStyles } from "@mui/styles";
import {
  DeleteMemberApi,
  DeleteMultiMemberApi,
  memberListing,
  member_login_api,
} from "src/DAL/member/member";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import { dd_date_format } from "src/utils/constant";
import FilteredChip from "src/components/FilteredChip";
import MemberFilter from "./MemberFilterNew";
import { convertToTitleCase, htmlDecode } from "src/utils/convertHtml";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false },
  { id: "number", label: "#", alignRight: false },
  { id: "name", label: " Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "school_name", label: "School Name", alignRight: false },
  { id: "referral_school", label: "Referred By", alignRight: false },
  { id: "planName", label: "Signed up for", alignRight: false },
  { id: "program", label: "Programme", alignRight: false },
  { id: "teacher_count", label: "Teachers Count", alignRight: false },
  { id: "last_activity", label: "Last Activity At", alignRight: false },
  { id: "CreatedAt", label: "Created At", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function MembersList() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("number");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openDelete, setOpenDelete] = useState(false);
  const [openMultiDelete, setOpenMultiDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [filterNameStatus, setFilterNameStatus] = useState(false);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");

  const EMPTY_FILTER = {
    program: [],
    statuss: "all",
    search_text: "",
  };

  const [filterState, setFilterState] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);

  let listing_name = "/member/";

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterState.search_text = searchText;
    getMemberListing(filterState);
    handleCloseFilterDrawer();
    localStorage.setItem("MemberListSearch", JSON.stringify(filterState));
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);

    getMemberListing(data);
    setFilterState(data);
    localStorage.setItem("MemberListSearch", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    let clear_data = { ...EMPTY_FILTER };
    setPage(0);
    setPageCount(1);
    setSearchText("");
    setFilterState(clear_data);
    getMemberListing(clear_data);
    handleCloseFilterDrawer();
    localStorage.removeItem("MemberListSearch");
  };
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
    setFilterState(filterStateUpdated);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const getMemberListing = async (filterData) => {
    let postData = {
      program: filterData.program,
      status: filterData.statuss,
      search_text: filterData.search_text,
    };
    if (postData?.program.length > 0) {
      postData.program = postData.program.map((program) => {
        return program._id;
      });
    }

    setIsLoading(true);

    const result = await memberListing(page, rowsPerPage, postData);

    if (result.code == 200) {
      let chipData = { ...filterData };
      if (chipData.search_text) {
        delete chipData.search_text;
      }
      setFilterStateUpdated(chipData);
      const members = result?.member?.map((member, index) => {
        return {
          ...member,
          id: member._id,
          fulName: member.first_name + " " + member.last_name,
          name: member.first_name,
          lastName: member.last_name,
          status: member.status,
          email: member.email,
          profile: member.profile_image,
          program: member.program,
          planName: member?.payment_info,
          goal: member.goal_statement_status,
          contact_number: member.contact_number,
          street: member.street,
          city: member.city,
          zip_code: member.zip_code,
          state: member.state,
          selectedCountry: member.country,
          consultant_id: member?.consultant?._id,
          time_zone: member.time_zone,
          member_biography: member.member_biography,
          profile_image: member.profile_image,
          first_payment_date: member.first_payment_date,
          selected_country: member.country,
          communityLevel: member.community_level,
          likeCount: member.like_count,
          gratitudeCount: member.gratitude_count,
          coinsCount: member.coins_count,
          createdAt: member.createdAt,
          gaolStatementCompletedStatus: member.goal_statement_completed_status,
          gaolStatementCompletedDate: member.goal_statement_completed_date,
          save_and_close_status: member.save_and_close_status,
          save_and_close_date: member.save_and_close_date,
          referral_name: member.refferal_name,
          max_teacher_limit: member?.payment_info?.max_teacher_limit,
          object: member,
          created_at: dd_date_format(member.createdAt),
          teacher_count: member.teacher_count ? member.teacher_count : 0,
          last_activity: member.lastActivity
            ? moment(member.lastActivity).format("DD-MM-YYYY hh:mm A")
            : "",
          count: index + 1 + rowsPerPage * page,
        };
      });

      setData(members);
      setTotalPages(result.total_pages);

      setTotalCount(result.total_member_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setSearchText(event.target.value);
  };

  const emptyRows = page > 0 && 0;

  const isUserNotFound = data.length === 0;

  const LoginAsMember = async (value) => {
    setIsLoading(true);
    const data = {
      email: value.email,
      user_type: "client",
    };

    const result = await member_login_api(data);
    if (result.code === 200) {
      window.open(`${clientPortalUrl}login-by-admin/${result?.token}`);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleEdit = (value) => {
    navigate(`/member/edit-member/${value.id}`, {
      state: value,
    });
  };
  const handleProfile = (value) => {
    navigate(`/member/member-profile/${value.id}`, {
      state: value,
    });
  };
  const handleChangePassword = (value) => {
    navigate(`/member/change-password/${value.id}`, {
      state: value,
    });
  };
  const handleChangeAllTransaction = (value) => {
    navigate(`/member/all-transaction/${value.id}`, {
      state: value,
    });
  };
  const handleChangeRoute = (route, data) => {
    navigate(route, { state: data });
  };
  const handleChangeAccess = (value) => {
    navigate(`/member/programme-access/${value.id}`, {
      state: value,
    });
  };
  const handleNavigate = () => {
    navigate(`/member/add-member`);
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleMemberSubscribers = (value) => {
    let route = `${listing_name}member-subscribers/${value._id}`;
    handleChangeRoute(route, value);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteMemberApi(deleteDoc.id);
    if (result.code === 200) {
      searchFunction();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleMultipleDelete = async () => {
    setOpenMultiDelete(false);

    const member_id = [];
    selected.map((id) => {
      member_id.push(id);
    });
    const formData = new FormData();
    formData.append("type", "specific");
    formData.append("member", JSON.stringify(member_id));
    setIsLoading(true);
    const result = await DeleteMultiMemberApi(formData);
    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
      setSelected([]);
      getMemberListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleIsDateRange = (event) => {
    const value = event.target.checked;
    setFilterState((values) => ({ ...values, is_date_range: value }));
  };
  const handleChangeOthers = (event, name) => {
    setFilterState((values) => ({ ...values, [name]: event }));
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilterState((values) => ({ ...values, [name]: value }));
  };
  const handleTeachersNavigate = (value) => {
    navigate(`/member/teachers/${value.id}`, {
      state: value,
    });
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Login As Member",
      icon: "ant-design:lock",
      handleClick: LoginAsMember,
    },
    {
      label: "View Subscriptions",
      icon: "eva:eye-fill",
      handleClick: handleMemberSubscribers,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Profile",
      icon: "iconoir:profile-circled",
      handleClick: handleProfile,
    },
    {
      label: "Change Password",
      icon: "ant-design:lock",
      handleClick: handleChangePassword,
    },
    {
      label: "Manage Programme Access",
      icon: "ant-design:key",
      handleClick: handleChangeAccess,
    },
    {
      label: "All Transactions",
      icon: "icon-park-outline:transaction-order",
      handleClick: handleChangeAllTransaction,
    },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("MemberListSearch");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterState(filter_data);
    setFilterStateUpdated(filter_data);
    getMemberListing(filter_data);
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (!filterNameStatus) {
      return;
    }
    getMemberListing(EMPTY_FILTER);
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openMultiDelete}
        setOpen={setOpenMultiDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleMultipleDelete}
      />
      <div className="container">
        <div className="row mt-3 mb-3">
          <div className="col-lg-6 col-sm-12">
            <h2>Members</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}>
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>

            <button onClick={handleNavigate} className="small-contained-button">
              Add Member
            </button>
          </div>
        </div>

        <FilteredChip
          data={filterStateUpdated}
          tempState={filterState}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />

        <Card style={{ overflowX: "auto" }}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column align-items-start">
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Pagination
                count={totalPages + 1}
                page={pageCount}
                defaultPage={0}
                onChange={handleChangePages}
                className="pagination-style"
              />
            </div>
            <div className="d-flex align-items-center">
              <UserListToolbar
                numSelected={selected.length}
                filterName={searchText}
                onFilterName={handleFilterByName}
              />
              <button
                onClick={() => searchFunction()}
                className="small-contained-button me-3 mb-1">
                Search
              </button>
            </div>
          </div>

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                // onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {data.map((row, i) => {
                  const {
                    id,
                    name,
                    fulName,
                    status,
                    email,
                    profile,
                    program,
                    planName,
                    school_name,
                    referral_school,
                    created_at,
                    last_activity,
                    teacher_count,
                    max_teacher_limit,
                  } = row;
                  const isItemSelected = selected.indexOf(id) !== -1;
                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}>
                      <TableCell>
                        <CustomPopoverSection menu={MENU_OPTIONS} data={row} />
                      </TableCell>
                      <TableCell align="left">
                        {page * rowsPerPage + (i + 1)}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Avatar alt={name} src={s3baseUrl + profile} />
                          <Typography variant="subtitle2" noWrap>
                            {fulName}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>{email}</TableCell>
                      <TableCell>{school_name ? school_name : "N/A"}</TableCell>
                      <TableCell>
                        {referral_school ? referral_school : "N/A"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle2" noWrap>
                          {planName?.plan_title
                            ? ` Sale Page (
                          ${
                            planName && planName.page_title
                              ? planName?.page_title + ` | `
                              : ""
                          }
                          ${
                            planName && planName?.plan_title
                              ? planName?.plan_title
                              : ""
                          }
                          ${planName && planName?.plan_type ? ` | ` : ""}
                         ${planName && convertToTitleCase(planName?.plan_type)}

                          )`
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="program-exp">
                        {program.map((program) => {
                          return (
                            <>
                              <Tooltip
                                title={
                                  <Typography fontSize={14}>
                                    {program.expiry_date
                                      ? " " +
                                        "Expiry: " +
                                        moment(program.expiry_date).format(
                                          "DD-MM-YYYY"
                                        )
                                      : "No Expiry"}
                                  </Typography>
                                }
                                arrow>
                                <p
                                  className="mb-1 modalIcon"
                                  onClick={() => handleChangeAccess(row)}>
                                  {program?._id?.title}
                                </p>
                              </Tooltip>
                            </>
                          );
                        })}
                      </TableCell>
                      <TableCell
                        className="modalIcon"
                        onClick={() => handleTeachersNavigate(row)}>
                        {`${teacher_count} / ${max_teacher_limit}`}
                      </TableCell>
                      <TableCell>{last_activity}</TableCell>

                      <TableCell>{created_at}</TableCell>

                      <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={status === false ? "error" : "success"}>
                          {status === false ? "InActive" : "Active"}
                        </Label>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={11} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={14} sx={{ py: 3 }}>
                      <SearchNotFound />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <MemberFilter
            filterData={filterState}
            handleChange={handleChange}
            setFilterData={setFilterState}
            handleIsDateRange={handleIsDateRange}
            handleChangeOthers={handleChangeOthers}
            searchSubmitFilter={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
}
