import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import { Stack, Container, Typography } from "@mui/material";
import { login } from "../DAL/Login/Login";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
import { s3baseUrl } from "src/config/config";
import { usePGIMode } from "src/Hooks/PGIModeContext";

// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { projectInfo } = usePGIMode();

  const handleSubmitLogin = async (data) => {
    setLoading(true);
    const result = await login(data);
    if (result.code === 200) {
      localStorage.setItem("token", result.token);
      localStorage.setItem("email", result.adminUser.email);
      localStorage.setItem("is_send_code", result.adminUser.is_send_code);
      localStorage.setItem(
        "is_verified_code",
        result.adminUser.is_verified_code
      );
      localStorage.setItem(
        "verification_code",
        result.adminUser.verification_code
      );
      localStorage.setItem("name", result.adminUser.name);
      localStorage.setItem("_id", result.adminUser._id);
      localStorage.setItem("image", result.adminUser.image.thumbnail_1);
      enqueueSnackbar(result.message, { variant: "success" });
      navigate("/dashboard", { replace: true });
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        background: `url(${
          s3baseUrl + projectInfo?.admin_background_image
        }) no-repeat center center`,
        backgroundSize: "cover",
        width: "100%",
        backgroundSize: "cover",
      }}
      className="bg"
    >
      <Container maxWidth="sm" className="">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom sx={{ color: "white" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: projectInfo?.admin_login_page_text,
                }}
              ></div>
            </Typography>
          </Stack>
          <LoginForm isLoading={loading} onhandleLogin={handleSubmitLogin} />
          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </div>
  );
}
