import { useFormik } from "formik";
import { useEffect, useState } from "react";
// material
import { CircularProgress } from "@mui/material";
//
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  admin_delete_videos,
  admin_videos_listing,
} from "src/DAL/HelpingVideos/AdminHelpingVideos";
import CustomPopover from "src/components/MenuOption/CustomPopover";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomPopoverForCard from "src/components/MenuOption/CustomPopoverForCard";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ClientVideos() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [adminVideos, setAdminVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const programsList = async () => {
    const result = await admin_videos_listing("client");
    if (result.code == 200) {
      setIsLoading(false);
      setAdminVideos(result.helpingvideo);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigateDetail = (value) => {
    navigate(
      `/client-portal-videos/client-videos-detail/${value.helping_video_slug}`,
      {
        state: { detailValues: value },
      }
    );
  };

  const handleNavigateEdit = (value) => {
    navigate(`/client-portal-videos/edit-client-videos`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await admin_delete_videos(deleteDoc.helping_video_slug);
    if (result.code === 200) {
      programsList();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleNavigateEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    programsList();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="row">
        <div className="col-lg-7 col-sm-12">
          <h2>Client Portal Helping videos </h2>
        </div>
        <div className="col-lg-5 col-sm-12 text-end">
          <button
            onClick={() => navigate(`/client-portal-videos/add-client-videos`)}
            className="small-contained-button"
          >
            Add Client Videos
          </button>
        </div>
        {adminVideos.map((value, index) => {
          return (
            <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
              <div className="card mt-4 pods-cards-shadow cursor h-100">
                <img
                  src={s3baseUrl + value.thumbnail_image.thumbnail_1}
                  className="card-img-top pods-image"
                  alt=""
                  onClick={() => handleNavigateDetail(value)}
                />
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <h3
                        className="h2-heading"
                        onClick={() => handleNavigateDetail(value)}
                      >
                        {htmlDecode(value.title)}
                      </h3>
                    </div>

                    <div className="col-2 menu-option">
                      <CustomPopoverForCard menu={MENU_OPTIONS} data={value} />
                    </div>
                  </div>
                  <p className="programme-card-desc">
                    {htmlDecode(value.short_description)}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
