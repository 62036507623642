import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  TextField,
  Container,
  Typography,
  Grid,
  Box,
  Stack,
  IconButton,
  Card,
  Radio,
  Chip,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { formsListDetail } from "src/DAL/Forms/Forms";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function DetailsFormInformation({ form }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const param = useParams();
  const classes = useStyles();
  const [templateTitle, setTemplateTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  async function getDetailsOfSurveyTemplate() {
    try {
      const res = await formsListDetail(form_id);
      // const res = await formsListDetail(param.id);
      if (res.code === 200) {
        let data = [];
        res?.form_template?.questions.map((item) => {
          data.push({ display: false });
        });

        setTemplateTitle(res.dynamic_form.form_title);
        setQuestions(res?.dynamic_form?.form_questions);
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    setTemplateTitle("Form Information");
    setQuestions(form);
    // getDetailsOfSurveyTemplate();
  }, []);
  //===================================================================
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <Container>
      <>
        <Stack
          direction="column"
          alignItems="center"
          spacing={1.5}
          justifyContent="space-between"
          mb={4.5}>
          {questions &&
            questions.map((item, index) => {
              return (
                <Card
                  className="w-100"
                  sx={{
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    mb: 1,
                    width: { sm: "100%", md: "100%" },
                    margin: "auto",
                    p: 2,
                    backgroundColor: "",
                    borderRadius: 0.5,
                    position: "relative",
                  }}>
                  {item?.is_required && (
                    <Chip
                      label={"Required"}
                      variant="contained"
                      color={"error"}
                      sx={{
                        fontSize: "10px",
                        height: "auto",
                        width: "auto",
                        position: "absolute",
                        top: 8,
                        left: 16,
                      }}
                    />
                  )}
                  <Box sx={{ width: "100%", margin: "auto" }} margin={0}>
                    <Grid
                      container
                      rowSpacing={0}
                      columnSpacing={{ xs: 0, sm: 0, md: 2 }}
                      mt={1}>
                      <Grid item md={12} xs={12}>
                        <div className="d-flex align-items-center mb-2">
                          <div className="">
                            <Typography
                              sx={{ fontWeight: "900", fontSize: 16, mr: 1 }}>
                              Q{index + 1}.
                            </Typography>
                          </div>
                          <div className="d-flex">
                            {item?.question_type == "checkbox" && (
                              <Checkbox checked={item.answer == "on"} />
                            )}

                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: 14,
                                mr: 1,
                                marginTop:
                                  item?.question_type == "checkbox"
                                    ? "9px"
                                    : "inherit",
                              }}>
                              {item?.question_statement}
                            </Typography>
                          </div>
                        </div>
                      </Grid>

                      {item?.question_type == "multiple_choice" ? (
                        <Grid item md={12} xs={12}>
                          {item?.values.map((val, index) => {
                            return (
                              <Grid item md={12}>
                                <div className="d-flex align-items-center mb-2">
                                  <div>
                                    <Radio checked={val === item.answer} />
                                  </div>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontSize: 14,
                                      mr: 1,
                                    }}>
                                    {val}
                                  </Typography>
                                </div>
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : item?.question_type == "multiple_select" ? (
                        <Grid item md={12} xs={12}>
                          {item?.values.map((val, index) => {
                            return (
                              <Grid item md={12}>
                                <div className="d-flex align-items-center mb-2">
                                  <div>
                                    {/* <Radio disabled /> */}
                                    <Radio checked={val === item.answer} />
                                  </div>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontSize: 14,
                                      mr: 1,
                                    }}>
                                    {val}
                                  </Typography>
                                </div>
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : item?.question_type == "dropdown" ? (
                        <Grid item md={12} xs={12}>
                          {item?.values.map((val, index) => {
                            return (
                              <Grid item md={12}>
                                <div className="d-flex align-items-center mb-2">
                                  <div>
                                    <p
                                      className="mt-3"
                                      style={{
                                        fontWeight:
                                          val === item.answer
                                            ? "bold"
                                            : "normal",
                                        color:
                                          val === item.answer
                                            ? "#ea7423"
                                            : "inherit",
                                      }}>{`${index + 1}.`}</p>
                                  </div>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontSize: 14,
                                      mr: 1,
                                      ml: 2,
                                      color:
                                        val === item.answer
                                          ? "#ea7423"
                                          : "inherit",
                                      fontWeight:
                                        val === item.answer ? "bold" : "normal",
                                    }}>
                                    {val}
                                  </Typography>
                                </div>
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : item?.question_type == "textbox" ? (
                        <Grid item md={12} xs={12}>
                          <TextField
                            id="outlined-multiline-static"
                            // label="Answer"
                            multiline
                            disabled
                            value={item?.answer}
                            rows={4}
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      ) : (
                        item?.question_type == "checkbox" && (
                          <Grid item md={12} xs={12}>
                            {/* <TextField
                              id="outlined-multiline-static"
                              multiline
                              disabled
                              rows={4}
                              sx={{
                                width: "100%",
                                "& fieldset": {
                                  top: 0,
                                },
                                "& legend": {
                                  display: "none",
                                },
                              }}
                            /> */}
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Box>
                </Card>
              );
            })}
        </Stack>
        {/* </form> */}
      </>
    </Container>
  );
}
