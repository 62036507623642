import { useEffect, useState } from "react";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { deleteProgramme, programmeListing } from "src/DAL/Programme/Programme";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import ListIcon from "@mui/icons-material/List";
import GridViewIcon from "@mui/icons-material/GridView";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import { show_proper_words } from "src/utils/constant";
import { deleteLessonApi } from "src/DAL/lessonDocument/LessonDocument";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ProgrammesList() {
  const classes = useStyles();
  const { viewMode, setViewMode } = usePGIMode();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [program, setProgram] = useState([]);
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const toggleView = () => {
    setViewMode((prevMode) => (prevMode === "grid" ? "table" : "grid"));
  };

  const programsList = async () => {
    const result = await programmeListing();
    if (result.code == 200) {
      setIsLoading(false);
      const ListData = result.program.map((items) => {
        let program_access = "N/A";
        if (items.program_access_type) {
          program_access = show_proper_words(items.program_access_type);
        }

        return {
          ...items,
          table_avatar: {
            src: s3baseUrl + items.program_images.thumbnail_1,
            alt: items.title,
          },
          is_defaults: items?.is_default ? "Yes" : "No",
          program_access,
        };
      });
      setProgram(ListData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigateDetail = (value) => {
    navigate(`/programmes/programmes-detail/${value.program_slug}`, {
      state: value,
    });
  };

  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
  };

  const get_short_string = (str, limit = 30) => {
    return str && str.length < limit ? str : str?.slice(0, limit) + ".... ";
  };
  const getFilteredData = (query) => {
    let dataToFilter = [...program];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async (value) => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteProgramme(deleteDoc?.program_slug);
    if (result.code === 200) {
      programsList();
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
    },
    {
      id: "title",
      label: "Title",
      alignRight: false,
      renderData: (row) => {
        return (
          <p
            className="mb-0 "
            style={{ cursor: "pointer" }}
            onClick={() => handleNavigateDetail(row)}
          >
            {row.title}
          </p>
        );
      },
    },
    {
      id: "title",
      label: "Total Lessons",
      alignRight: false,
      renderData: (row) => {
        return <span> {row.no_of_lesson} Lessons</span>;
      },
    },
    {
      id: "title",
      label: "Lessons Duration",
      alignRight: false,
      renderData: (row) => {
        return <span> {row.total_lesson_duration} </span>;
      },
    },

    { id: "program_access", label: "Program Access Type", alignRight: false },
    {
      id: "no_of_start_days",
      label: "No Of Start Days",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {row.program_access_type == "limited"
                ? row.no_of_start_days
                : "--"}
            </span>
          </>
        );
      },
    },
    {
      id: "no_of_limited_days",
      label: "No Of Limited Days",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {row.program_access_type == "limited"
                ? row.no_of_limited_days
                : "--"}
            </span>
          </>
        );
      },
    },
    { id: "order", label: "Order", alignRight: false },
    { id: "status", label: "Status", alignRight: false, type: "row_status" },
  ];

  const handleNavigateEdit = (row) => {
    navigate(`/programmes/edit-programme/${row.program_slug}`, {
      state: row.program_slug,
    });
  };

  const handleNavigateAdd = (row) => {
    navigate(`/programmes/${"add"}/${row._id}`, {
      state: row,
    });
  };

  const handleNavigateRemove = (row) => {
    navigate(`/programmes/${"remove"}/${row._id}`, {
      state: row,
    });
  };

  const handleLockedProgramInfo = (row) => {
    navigate(`/programmes/locked-programme/${row.program_slug}`, {
      state: row,
    });
  };

  const handleManageLessons = (row) => {
    navigate(`/programmes/lessons-listing/${row.program_slug}`, {
      state: {
        name: row?.title,
        slug: row?.program_slug,
      },
    });
  };

  const handleManageMember = (row) => {
    navigate(`/programmes/members/${row._id}`, {
      state: row,
    });
  };

  let MENU_OPTIONS = [
    {
      label: "Manage Lessons",
      icon: "akar-icons:edit",
      handleClick: handleManageLessons,
    },
    {
      label: "Manage Member",
      icon: "akar-icons:edit",
      handleClick: handleManageMember,
    },
    {
      label: "Locked Programme Info",
      icon: "akar-icons:edit",
      handleClick: handleLockedProgramInfo,
    },
    {
      label: "Add Member Programme Access",
      icon: "akar-icons:edit",
      handleClick: handleNavigateAdd,
    },
    {
      label: "Remove Member Programme Access",
      icon: "akar-icons:edit",
      handleClick: handleNavigateRemove,
    },
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleNavigateEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    programsList();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="row">
        <div className="col-lg-4 col-md-8 ">
          <h2>Programme </h2>
        </div>
        <div
          className="col-lg-8 col-md-4 text-end"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <dv>
            <FormControl variant="outlined" style={{ marginTop: "2px" }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Search
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                variant="small"
                onChange={handleChangeSearch}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search"
              />
            </FormControl>
          </dv>

          <button
            onClick={() => navigate(`/programmes/add-programme`)}
            className="ms-3 mt-1 small-contained-button "
          >
            Add Programme
          </button>

          <button
            onClick={toggleView}
            className="ms-1 mt-1 small-contained-button button_icon"
          >
            {viewMode == "grid" ? (
              <>
                <GridViewIcon />
              </>
            ) : (
              <>
                <ListIcon />
              </>
            )}
          </button>
        </div>

        {viewMode == "table" ? (
          <>
            <div className="mt-4">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                MENU_OPTIONS={MENU_OPTIONS}
                data={getFilteredData(query)}
                className="card-with-background"
                pagePagination={true}
                is_hide={true}
              />
            </div>
          </>
        ) : (
          <>
            {getFilteredData(query)?.map((value, index) => {
              return (
                <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                  <div className="card mt-4 pods-cards-shadow cursor h-100">
                    <img
                      src={s3baseUrl + value.program_images.thumbnail_1}
                      className="card-img-top pods-image"
                      alt="Programme"
                      onClick={() => handleNavigateDetail(value)}
                    />
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <h3
                            className="h2-heading"
                            onClick={() => handleNavigateDetail(value)}
                          >
                            {htmlDecode(value.title)}
                          </h3>
                        </div>
                      </div>
                      <p
                        className="programme-card-desc mb-3"
                        onClick={() => handleNavigateDetail(value)}
                      >
                        {get_short_string(
                          htmlDecode(value.short_description),
                          140
                        )}
                        {value.short_description.length > 140 ? (
                          <span className="see-more-button">see more</span>
                        ) : (
                          ""
                        )}
                      </p>
                      <div className="row recording-card-date">
                        <div className="col-5 card-button recording-card-date-position">
                          <p className="pods-active-members">
                            <span> {value.no_of_lesson} Lessons</span>
                          </p>
                        </div>
                        <div className="col-7 text-end ms-auto">
                          {value.total_lesson_duration}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
