import PropTypes from "prop-types";
// material
import { Box } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import { usePGIMode } from "src/Hooks/PGIModeContext";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  const { logo } = usePGIMode();
  return <Box component="img" src={s3baseUrl + logo} sx={{ width: 150 }} />;
}
