import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  TextField,
  Container,
  Typography,
  Grid,
  Box,
  Stack,
  IconButton,
  Card,
  Radio,
  Chip,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { formsListDetail } from "src/DAL/Forms/Forms";
import { pdfImage } from "src/assets";
import { s3baseUrl } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function DetailsFormInformation() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const param = useParams();
  const classes = useStyles();
  const [templateTitle, setTemplateTitle] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);

  async function getDetailsOfSurveyTemplate() {
    try {
      const res = await formsListDetail(param.id);
      if (res.code === 200) {
        let data = [];
        res?.form_template?.questions.map((item) => {
          data.push({ display: false });
        });

        setTemplateTitle(res.dynamic_form.form_title);
        setQuestions(res?.dynamic_form?.form_questions);
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getDetailsOfSurveyTemplate();
  }, []);
  //===================================================================
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <Container>
      <Stack
        direction="column"
        alignItems="left"
        justifyContent="space-between">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}>
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
      </Stack>
      <>
        <Stack
          direction="column"
          alignItems="center"
          spacing={1.5}
          justifyContent="space-between"
          mb={4.5}>
          <h2 className="text-start w-100">{templateTitle}</h2>

          {questions &&
            questions.map((item, index) => {
              console.log(item, "heloooooooooo");
              return (
                <Card
                  className="w-100"
                  sx={{
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    mb: 1,
                    width: { sm: "100%", md: "100%" },
                    margin: "auto",
                    p: 2,
                    backgroundColor: "",
                    borderRadius: 0.5,
                    position: "relative",
                  }}>
                  {item?.is_required && (
                    <Chip
                      label={"Required"}
                      variant="contained"
                      color={"error"}
                      sx={{
                        fontSize: "10px",
                        height: "auto",
                        width: "auto",
                        position: "absolute",
                        top: 8,
                        left: 16,
                      }}
                    />
                  )}
                  <Box sx={{ width: "100%", margin: "auto" }} margin={0}>
                    <Grid
                      container
                      rowSpacing={0}
                      columnSpacing={{ xs: 0, sm: 0, md: 2 }}
                      mt={1}>
                      <Grid item md={12} xs={12}>
                        <div className="d-flex align-items-center mb-2">
                          <div className="">
                            <Typography
                              sx={{ fontWeight: "900", fontSize: 16, mr: 1 }}>
                              Q{index + 1}.
                            </Typography>
                          </div>
                          <div className="d-flex">
                            {item?.question_type == "checkbox" && (
                              <Checkbox disabled />
                            )}
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: 14,
                                mr: 1,
                                marginTop:
                                  item?.question_type == "checkbox"
                                    ? "9px"
                                    : "inherit",
                              }}>
                              {item?.question_statement}
                            </Typography>
                          </div>
                        </div>
                      </Grid>

                      {item?.question_type == "multiple_choice" ? (
                        <Grid item md={12} xs={12}>
                          {item?.values.map((item) => {
                            return (
                              <Grid item md={12}>
                                <div className="d-flex align-items-center mb-2">
                                  <div>
                                    <Radio disabled />
                                  </div>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontSize: 14,
                                      mr: 1,
                                    }}>
                                    {item}
                                  </Typography>
                                </div>
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : item?.question_type == "multiple_select" ? (
                        <Grid item md={12} xs={12}>
                          {item?.values.map((item) => {
                            return (
                              <Grid item md={12}>
                                <div className="d-flex align-items-center mb-2">
                                  <div>
                                    <Radio disabled />
                                  </div>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontSize: 14,
                                      mr: 1,
                                    }}>
                                    {item}
                                  </Typography>
                                </div>
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : item?.question_type == "dropdown" ? (
                        <Grid item md={12} xs={12}>
                          {item?.values.map((item, index) => {
                            return (
                              <Grid item md={12}>
                                <div className="d-flex align-items-center mb-2">
                                  <div>
                                    <p
                                      className="mt-3"
                                      style={{ fontWeight: "bold" }}>{`${
                                      index + 1
                                    }.`}</p>
                                  </div>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontSize: 14,
                                      mr: 1,
                                      ml: 2,
                                    }}>
                                    {item}
                                  </Typography>
                                </div>
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : item?.question_type == "textbox" ? (
                        <Grid item md={12} xs={12}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Answer"
                            multiline
                            disabled
                            rows={4}
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      ) : (
                        item?.question_type == "checkbox" && (
                          <Grid item md={12} xs={12}>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="row w-100 div-style ms-0 pt-0">
                                <div className="col-5"></div>
                                <div className="col-3">
                                  {item.attachment && (
                                    <img src={pdfImage} height="50" />
                                  )}
                                </div>

                                <div className="col-4 text-end pt-2 link-button mt-3">
                                  {item.attachment && (
                                    <a
                                      href={s3baseUrl + item.attachment}
                                      target="_blank"
                                      className="me-2 small-contained-button">
                                      View File
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Box>
                </Card>
              );
            })}
        </Stack>
        {/* </form> */}
      </>
    </Container>
  );
}
