import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [];

sidebarConfig.push(
  {
    title: "dashboard",
    path: "/dashboard",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "Programmes",
    path: "/programmes",
    icon: getIcon(barchartfill),
  },
  {
    title: "Catagories",
    path: "/catagory",
    icon: getIcon(barchartfill),
  },
  {
    title: "Members",
    path: "/member",
    icon: getIcon(barchartfill),
  }
);

sidebarConfig.push({
  title: "Payments",
  path: "/list",

  icon: getIcon("ic:round-date-range"),
  children: [
    {
      title: "Stripe Products",
      path: "/stripe-products",

      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Transactions",
      path: "/payment-transactions",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Bookings",
      path: "/bookings",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Payment Templates",
      path: "/payment-templates",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Payment Requests",
      path: "/payment-requests",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Subscription List",
      path: "/subscription-list",
      icon: getIcon("wpf:renew-subscription"),
    },
  ],
});

sidebarConfig.push({
  title: "Calendar",
  path: "/list",

  icon: getIcon("ic:round-date-range"),
  children: [
    {
      title: "Groups",
      path: "/calendar-groups",

      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Calendar Events",
      path: "/calendarEvents",
      icon: getIcon("ic:round-date-range"),
    },
    // {
    //   title: "Pods",
    //   path: "/calendar-pods",
    //   icon: getIcon("ic:round-date-range"),
    // },
  ],
});
sidebarConfig.push({
  title: "Support",
  path: "/list",

  icon: getIcon("ic:round-date-range"),
  children: [
    {
      title: "Support Tickets",
      path: "/support-tickets",

      icon: getIcon("ic:round-date-range"),
    },
  ],
});
sidebarConfig.push({
  title: "Client Portal",
  path: "/list",

  icon: getIcon("fluent:settings-20-filled"),
  children: [
    {
      title: "General Setting",
      path: "/general-setting",

      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Client Dashboard",
      path: "/client-dashboard",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Content Setting",
      path: "/content-setting",
      icon: getIcon("ic:round-date-range"),
    },

    // {
    //   title: "Default Events",
    //   path: "/dynamite-events",
    //   icon: getIcon("ic:round-date-range"),
    // },
  ],
});
sidebarConfig.push({
  title: "General Setting",
  path: "/list",

  icon: getIcon("fluent:settings-20-filled"),
  children: [
    {
      title: "Default Setting",
      path: "default-setting",

      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Email Setting",
      path: "/email-setting",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Stripe Setting",
      path: "/stripe-setting",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Campaign Setting",
      path: "/campaign-setting",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Calendar Setting",
      path: "/calendar-setting",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Invoice Setting",
      path: "/invoice-setting",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Email Templates",
      path: "/email-templates",
      icon: getIcon("ic:round-date-range"),
    },
  ],
});
sidebarConfig.push({
  title: "Manage Website",
  path: "/list",

  icon: getIcon("fluent:settings-20-filled"),
  children: [
    {
      title: "Pages",
      path: "/website-pages",

      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Template Pages",
      path: "/template-pages",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Menus",
      path: "/menus",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Form Templates",
      path: "/form-templates",
      icon: getIcon("ic:round-date-range"),
    },
  ],
});
sidebarConfig.push({
  title: "Helping Videos",
  path: "/list",

  icon: getIcon("fluent:video-clip-20-filled"),
  children: [
    {
      title: "Admin Videos",
      path: "/admin-videos",

      icon: getIcon("ic:round-video-library"),
    },
    {
      title: "Client Portal Videos",
      path: "/client-portal-videos",
      icon: getIcon("ic:round-video-library"),
    },
  ],
});
export default sidebarConfig;
