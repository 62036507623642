import React, { useState } from "react";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Box,
  Card,
  Radio,
  Checkbox,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import { Icon } from "@iconify/react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { uploadPDFOns3 } from "src/DAL/commonApi/commonApi";
import { pdfImage } from "src/assets";
import { s3baseUrl } from "src/config/config";
import { styled } from "@mui/material/styles";

const Input = styled("input")({
  display: "none",
});
// ----------------------------------------------------------------------

export default function EditTemplate({
  questionsToSend,
  index,
  answerGenius,
  setQuestionsToSend,
  editShow,
  setEditShow,
}) {
  const [oldImageOther, setOldImageOther] = React.useState("");
  const [otherDocument, setOtherDocument] = React.useState();
  const [uploading, setUploading] = useState(false);

  const updateTitleChanged = (questionsToSend, index) => (e) => {
    if (e.target.value[0] === " ") {
      return;
    }
    questionsToSend[index]["title"] = e.target.value;
    setQuestionsToSend([...questionsToSend]);
  };
  const updateOrderChanged = (questionsToSend, index) => (e) => {
    if (e.target.value[0] === " ") {
      return;
    }
    if (e.target.value > questionsToSend.length) {
      return; // If greater than array.length + 1, set it to array.length + 1
    }
    if (e.target.value === "0" || e.target.value === 0) {
      return; // If greater than array.length + 1, set it to array.length + 1
    }
    const newOrder = parseInt(e.target.value);
    questionsToSend[index]["order"] = newOrder;
    setQuestionsToSend([...questionsToSend]);
  };

  const handleChangeQuestionType = (questionsToSend, index) => (e) => {
    questionsToSend[index].type = e.target.value;
    questionsToSend[index].options_count = 0;
    questionsToSend[index].options = ["", ""];
    setQuestionsToSend([...questionsToSend]);
  };
  const handleChangeQuestionTypeRequird = (questionsToSend, index) => (e) => {
    questionsToSend[index].required = e.target.value;
    setQuestionsToSend([...questionsToSend]);
  };
  const handleChangeCheckboxText = (questionsToSend, index) => (e) => {
    questionsToSend[index].description = e.target.value;
    setQuestionsToSend([...questionsToSend]);
  };
  const handleSubmitEditCase = async (e) => {
    e.preventDefault();

    const updatedQuestions = [...questionsToSend];
    const currentOrder = updatedQuestions[index].order;
    if (index > currentOrder) {
      // console.log("move up");
      for (let i = currentOrder - 1; i < index; i++) {
        updatedQuestions[i].order += 1;
      }
      // updatedQuestions[index].order = currentOrder;
    } else if (currentOrder - 1 == index) {
      // console.log("same position");
    } else if (currentOrder == index) {
      // console.log("move 1 up");
      let newIndex = parseInt(index - 1);
      updatedQuestions[newIndex].order += 1;
    } else if (index < currentOrder) {
      // console.log("move down");
      for (let i = index + 1; i < currentOrder; i++) {
        updatedQuestions[i].order -= 1;
      }
    }
    updatedQuestions.sort((a, b) => a.order - b.order);

    setQuestionsToSend(updatedQuestions);
    editShow[index].display = false;
    setEditShow([...editShow]);
  };

  const fileChangedHandlerOther = async (index, e) => {
    setUploading(true);

    setOtherDocument(URL.createObjectURL(e.target.files[0]));

    const formData = new FormData();
    formData.append("image", e.target.files[0]);

    const imageUpload = await uploadPDFOns3(formData);
    if (imageUpload.code == 200) {
      questionsToSend[index].attachment = imageUpload.image_path;

      setOldImageOther(imageUpload.image_path);
      setUploading(false);

      // console.log(imageUpload, "imageUploadimageUpload");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  // ------------------------------------
  return (
    <Card
      className="w-100"
      sx={{
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        mb: 1,
        width: { sm: "100%", md: "100%" },
        margin: "auto",
        p: 2,
      }}>
      <form onSubmit={handleSubmitEditCase} autoComplete="off">
        <Box sx={{ width: "100%", margin: "auto" }} margin={0}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 0, sm: 0, md: 2 }}
            mt={1}>
            <Grid item md={12} xs={12}>
              <TextField
                id="outlined-basic"
                label="Question Statement"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="question_statement"
                value={questionsToSend[index].title}
                onChange={updateTitleChanged(questionsToSend, index)}
              />
            </Grid>
            <Grid item md={4.5} xs={12}>
              <FormControl sx={{ mb: 0, width: "100%" }}>
                <InputLabel id="demo-simple-selec-label">
                  Question Type*
                </InputLabel>
                <Select
                  size="small"
                  required
                  labelId="demo-simple-selec-label"
                  id="demo-simple-select"
                  name="question_type"
                  value={questionsToSend[index]?.type}
                  label="Question Type*"
                  onChange={handleChangeQuestionType(questionsToSend, index)}>
                  <MenuItem value="multiple_choice">
                    Multiple Choice (single select)
                  </MenuItem>
                  <MenuItem value="multiple_select">
                    Multiple Choice (Multiple select)
                  </MenuItem>
                  <MenuItem value="textbox">Text</MenuItem>
                  <MenuItem value="dropdown">Dropdown</MenuItem>
                  <MenuItem value="checkbox">Checkbox</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
              <FormControl sx={{ mb: 0, width: "100%" }} required>
                <InputLabel id="demo-simple-selec-label1" size="small">
                  Is it Requird?
                </InputLabel>
                <Select
                  size="small"
                  required
                  labelId="demo-simple-selec-label1"
                  id="demo-simple-select"
                  name="is_required"
                  value={questionsToSend[index]?.required}
                  label="Is it Requird"
                  onChange={handleChangeQuestionTypeRequird(
                    questionsToSend,
                    index
                  )}>
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
              <Tooltip
                title={`Order cannot be 0 or greater then ${questionsToSend.length}`}>
                <TextField
                  id="outlined-basic"
                  label="Order"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  size="small"
                  type="number"
                  required
                  name="order"
                  onWheel={(event) => event.target.blur()}
                  value={questionsToSend[index].order}
                  onChange={updateOrderChanged(questionsToSend, index)}
                />
              </Tooltip>
            </Grid>
            <Grid item md={12} xs={12}>
              {questionsToSend[index]?.type != "checkbox" && (
                <hr className="mb-0 " />
              )}
            </Grid>
            {questionsToSend[index]?.type == "multiple_choice" ? (
              <MultipleChoiceOptions
                questionsToSend={questionsToSend}
                index={index}
                setQuestionsToSend={setQuestionsToSend}
                answerGenius={answerGenius}
              />
            ) : questionsToSend[index]?.type == "multiple_select" ? (
              <MultipleChoiceOptions
                questionsToSend={questionsToSend}
                index={index}
                setQuestionsToSend={setQuestionsToSend}
                answerGenius={answerGenius}
              />
            ) : questionsToSend[index]?.type == "dropdown" ? (
              <DropDown
                questionsToSend={questionsToSend}
                index={index}
                setQuestionsToSend={setQuestionsToSend}
                answerGenius={answerGenius}
              />
            ) : questionsToSend[index]?.type == "checkbox" ? (
              <Grid item md={12} xs={12}>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="row w-100 div-style ms-0 pt-0">
                    <div className="col-5">
                      <p className="">Upload PDF</p>
                    </div>
                    <div className="col-2">
                      {questionsToSend[index]?.attachment && (
                        <img src={pdfImage} height="50" />
                      )}
                    </div>

                    <div className="col-5 text-end pt-2 link-button">
                      {questionsToSend[index]?.attachment && (
                        <a
                          href={s3baseUrl + questionsToSend[index]?.attachment}
                          target="_blank"
                          className="me-2 small-contained-button">
                          View File
                        </a>
                      )}
                      <label htmlFor="contained-button-file-other">
                        <Input
                          accept=".pdf"
                          id="contained-button-file-other"
                          multiple
                          type="file"
                          name="otherDocument"
                          onChange={(e) => fileChangedHandlerOther(index, e)}
                        />

                        <Button
                          className="small-contained-button"
                          startIcon={<FileUploadIcon />}
                          component="span">
                          {uploading ? "Uploading..." : "Upload"}
                        </Button>
                      </label>
                    </div>
                  </div>
                  {/* {inputs.otherDocument.name == "" ? (
                          ""
                        ) : (
                          <p>{inputs.otherDocument.name}</p>
                        )} */}
                </div>
              </Grid>
            ) : (
              <Grid item md={12} xs={12}>
                <TextField
                  id="outlined-multiline-static"
                  label="Answer"
                  multiline
                  disabled
                  rows={6}
                  sx={{ width: "100%" }}
                />
              </Grid>
            )}

            <Grid item md={12} xs={12}>
              <hr className="m-0 " />
            </Grid>
          </Grid>

          <div className="col-12 mt-3 text-end">
            <button className="small-contained-button" type="submit">
              <Icon
                style={{ marginRight: "5px", width: 20, height: 20 }}
                icon="dashicons:saved"
              />
              Save
            </button>
          </div>
        </Box>
      </form>
    </Card>
  );
}

function MultipleChoiceOptions({
  questionsToSend,
  index,
  setQuestionsToSend,
  answerGenius,
}) {
  const [answerGeniusTitleInEdit, setAnswerGeniusTitleInEdit] = useState("");
  const handleChangeInMultipleChoiceEditCase =
    (questionsToSend, indexInChoice) => (e) => {
      if (e.target.value[0] === " ") {
        return;
      }
      questionsToSend[index].options[indexInChoice] = e.target.value;
      setQuestionsToSend([...questionsToSend]);
    };

  const handelAddChoiceEditCase = (questionsToSend, indexInChoice) => (e) => {
    let list = questionsToSend[index].options;
    list.splice(indexInChoice + 1, 0, "");
    questionsToSend[index].options = list;
    setQuestionsToSend([...questionsToSend]);
  };
  const handelDelChoiceEditCase = (questionsToSend, indexInChoice) => (e) => {
    if (questionsToSend[index].options.length === 2) {
      return;
    }
    let list = questionsToSend[index].options;
    list.splice(indexInChoice, 1);
    questionsToSend[index].options = list;
    setQuestionsToSend([...questionsToSend]);
  };
  return (
    <>
      <Grid item md={12} xs={12}>
        {questionsToSend[index]?.options.map((item, index) => {
          // console.log("item&index", item, index);
          return (
            <div className="d-flex align-items-center mb-2">
              <div>
                <Radio disabled />
              </div>
              <div className="w-100">
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  sx={{
                    width: "100%",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  required
                  name="question_statement"
                  placeholder="Enter Choice"
                  value={item}
                  onChange={handleChangeInMultipleChoiceEditCase(
                    questionsToSend,
                    index
                  )}
                />
              </div>
              <div>
                <Icon
                  icon="fluent:add-circle-20-regular"
                  color="#3da5f4"
                  width="30"
                  height="30"
                  onClick={handelAddChoiceEditCase(questionsToSend, index)}
                />
              </div>
              <div>
                <Icon
                  icon="fluent:subtract-circle-28-regular"
                  color="#ff4979"
                  width="30"
                  height="30"
                  onClick={handelDelChoiceEditCase(questionsToSend, index)}
                />
              </div>
            </div>
          );
        })}
      </Grid>
    </>
  );
}
function DropDown({
  questionsToSend,
  index,
  setQuestionsToSend,
  answerGenius,
}) {
  const handleChangeInMultipleChoiceEditCase =
    (questionsToSend, indexInChoice) => (e) => {
      if (e.target.value[0] === " ") {
        return;
      }
      questionsToSend[index].options[indexInChoice] = e.target.value;
      setQuestionsToSend([...questionsToSend]);
    };

  const handelAddChoiceEditCase = (questionsToSend, indexInChoice) => (e) => {
    let list = questionsToSend[index].options;
    list.splice(indexInChoice + 1, 0, "");
    questionsToSend[index].options = list;
    setQuestionsToSend([...questionsToSend]);
  };
  const handelDelChoiceEditCase = (questionsToSend, indexInChoice) => (e) => {
    if (questionsToSend[index].options.length === 2) {
      return;
    }
    let list = questionsToSend[index].options;
    list.splice(indexInChoice, 1);
    questionsToSend[index].options = list;
    setQuestionsToSend([...questionsToSend]);
  };
  return (
    <>
      <Grid item md={12} xs={12}>
        {questionsToSend[index]?.options.map((item, index) => {
          return (
            <div className="d-flex align-items-center mb-2">
              <div className="w-100">
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  sx={{
                    width: "100%",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  required
                  name="question_statement"
                  placeholder="Enter Option"
                  value={item}
                  onChange={handleChangeInMultipleChoiceEditCase(
                    questionsToSend,
                    index
                  )}
                />
              </div>
              <div>
                <Icon
                  icon="fluent:add-circle-20-regular"
                  color="#3da5f4"
                  width="30"
                  height="30"
                  onClick={handelAddChoiceEditCase(questionsToSend, index)}
                />
              </div>
              <div>
                <Icon
                  icon="fluent:subtract-circle-28-regular"
                  color="#ff4979"
                  width="30"
                  height="30"
                  onClick={handelDelChoiceEditCase(questionsToSend, index)}
                />
              </div>
            </div>
          );
        })}
      </Grid>
    </>
  );
}
