import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export default function ProgrammeListMemberProfile({ programmes }) {
  const navigate = useNavigate();

  const handleNavigateDetail = (value) => {
    navigate(`/programmes/programmes-detail/${value._id.program_slug}`, {
      state: { detailValues: value },
    });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-7 col-sm-12">
          <h2>Programme </h2>
        </div>
        <div className="col-lg-5 col-sm-12 text-end"></div>
        {programmes.map((value, index) => {
          return (
            <>
              <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                <div className="card mt-4 pods-cards-shadow cursor h-100">
                  <img
                    src={s3baseUrl + value._id.program_images.thumbnail_1}
                    className="card-img-top pods-image"
                    alt="Programme"
                    onClick={() => handleNavigateDetail(value)}
                  />
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <h3
                          className="h2-heading"
                          onClick={() => handleNavigateDetail(value)}
                        >
                          {htmlDecode(value._id.title)}
                        </h3>
                      </div>
                    </div>
                    <p className="programme-card-desc mb-3">
                      {htmlDecode(value._id.short_description)}
                    </p>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
