import React, { useState } from "react";
import { Button, CircularProgress, IconButton, TextField } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import {
  addWebsitePolicy,
  detailWebsitePolicyApi,
  editWebsitePolicy,
} from "src/DAL/WebsitePages/Policy";
import {
  handleImageExtensions,
  urlPatternValidation,
} from "src/utils/constant";
import { useEffect } from "react";
import { page_detail_by_id } from "src/DAL/WebsitePages/websitepages";
import { s3baseUrl } from "src/config/config";
import { pdfImage } from "src/assets";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const Input = styled("input")({
  display: "none",
});

const AddOrUpdateWebPagePdf = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [file, setProfileImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const [moduleData, setModuleData] = useState({});
  const [moduleSizes, setModuleSizes] = useState({});
  const [pageData, setPageData] = useState({});
  const module_actual_name = "policy_service";
  const [formType, setFormType] = useState("ADD");
  const [oldImage, setOldImage] = useState("");
  const [otherDocument, setOtherDocument] = React.useState();
  const [oldImageOther, setOldImageOther] = React.useState("");
  const [otherStatus, setOtherStatus] = useState(false);

  const [inputs, setInputs] = useState({
    title: "",
    name: "",
    testimonial_type: "image",
    button_text: "",
    button_link: "",
    description: "",
    status: "true",
    image: {},
    video_url: "",
    rating: "0",
    otherDocument: {},
  });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const getPageDetail = async () => {
    setIsLoading(true);
    const result = await page_detail_by_id(params.page_id);
    if (result.code == 200) {
      handlePageDetail(result.sale_page);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const fileChangedHandlerOther = (e) => {
    setOtherStatus(true);

    setOtherDocument(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["otherDocument"]: e.target.files[0],
    });
  };
  const handlePageDetail = (data) => {
    setPageData(data);
    const get_module_info = data.module_info.filter(
      (item) => item.module_actual_name == module_actual_name
    )[0];
    setModuleData(get_module_info.module_replica_info);
    console.log(get_module_info, "get_module_infoget_module_info");
    setModuleSizes(get_module_info);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (formType === "ADD" && !file) {
      setIsLoading(false);
      enqueueSnackbar("Please Upload Image", {
        variant: "error",
      });
      return;
    }
    if (formType === "ADD" && !otherStatus) {
      setIsLoading(false);
      enqueueSnackbar("Please Upload PDF", {
        variant: "error",
      });
      return;
    }
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("status", inputs.status);
    if (params && params.page_id) {
      formData.append("page_id", params.page_id);
    }

    if (otherDocument) {
      formData.append("file", inputs.otherDocument);
    }
    if (formType === "EDIT") {
      formData.append("order", inputs.order);
    }

    if (formType == "EDIT") {
      if (file) {
        formData.append("image", inputs.image);
      }
    } else {
      if (file) {
        formData.append("image", inputs.image);
      }
    }
    console.log(...formData, "======formdata");
    const result =
      formType == "ADD"
        ? await addWebsitePolicy(formData)
        : await editWebsitePolicy(formData, params.policy_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getTestimonialDetailData = async () => {
    setIsLoading(true);

    const result = await detailWebsitePolicyApi(params.policy_id);
    if (result.code === 200) {
      const { images } = result.policy;
      setFormType("EDIT");
      setOldImage(images.thumbnail_1);
      setInputs({
        ...result.policy,
        ["image"]: images?.thumbnail_1,
      });
      setOldImageOther(result.policy.file);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params && params.policy_id) {
      if (state) {
        if (params.page_id) {
          handlePageDetail(state.page_info);
        }
        setFormType("EDIT");
        setInputs(state.data);
        console.log(state, "stateeeeeeeeeeeeeee");
        setOldImageOther(state.data?.file);

        setOldImage(state.data.images.thumbnail_1);
      } else {
        getTestimonialDetailData();
        if (params.page_id) {
          getPageDetail();
        }
      }
    } else {
      if (params.page_id) {
        if (state) {
          handlePageDetail(state);
        } else {
          getPageDetail();
        }
      }
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 display-flex mb-4">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <span className="sale-page-title">{pageData.sale_page_title}</span>
        </div>
        <div className="col-6">
          <h2>
            {moduleData?.add_page_heading ? (
              <h2>
                {formType === "ADD"
                  ? moduleData.add_page_heading
                  : moduleData.edit_page_heading}
              </h2>
            ) : (
              <h2>{`${formType === "ADD" ? "Add" : "Edit"} Policy`}</h2>
            )}
          </h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>

          {formType !== "ADD" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                required
                name="order"
                value={inputs.order}
                onChange={handleChange}
              />
            </div>
          )}

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status"
                required
                onChange={handleChange}>
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(
                  {moduleSizes?.image_width + " x " + moduleSizes?.image_height}
                  ) ({handleImageExtensions(moduleSizes?.image_extension)})
                </FormHelperText>
              </div>
              <div className="col-2">
                {file ? (
                  <img className="image-border" src={file} height="50" />
                ) : (
                  oldImage && (
                    <img
                      className="image-border"
                      src={s3baseUrl + oldImage}
                      height="50"
                    />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    hidden
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span">
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload PDF*</p>
                <FormHelperText className="pt-0">
                  Upload the PDF for Policy
                </FormHelperText>
              </div>
              <div className="col-2">
                {(otherDocument || oldImageOther) && (
                  <img src={pdfImage} height="50" />
                )}
              </div>
              <div className="col-5 text-end pt-2 link-button">
                {oldImageOther && (
                  <a
                    href={s3baseUrl + oldImageOther}
                    target="_blank"
                    className="me-2 small-contained-button">
                    View File
                  </a>
                )}
                <label htmlFor="contained-button-file-other">
                  <Input
                    accept=".pdf"
                    id="contained-button-file-other"
                    multiple
                    type="file"
                    name="otherDocument"
                    onChange={fileChangedHandlerOther}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span">
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.otherDocument?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.otherDocument?.name}</p>
            )}
          </div>

          <div className="text-end mt-4">
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddOrUpdateWebPagePdf;
