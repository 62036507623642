import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import Label from "src/components/Label";
import ProgrammeListMemberProfile from "./ProgrammeListMemberProfile";
import { memberDetailApi, memberPodsListing } from "src/DAL/member/member";
import PodsListMemberProfile from "./PodsListMemberProfile";
import { useSnackbar } from "notistack";
import { CircularProgress, Container, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RecordNotFound from "src/components/RecordNotFound";
import { dummyImage } from "src/assets";
import ActiveLastBreadcrumb from "src/components/ActivePaths";
import GeneralCalendar from "src/components/GeneralComponents/GeneralCalendar";
import moment from "moment/moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const get_view_dates = (calendarValue) => {
  let tool = calendarValue;

  const startOfMonth = moment().startOf(tool);
  const daysToSubtract = startOfMonth.day() - 0; // 0 represents Sunday
  const previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();
  let add_days = 42;
  if (tool == "week") {
    add_days = 6;
  } else if (tool == "day") {
    add_days = 0;
  }
  let nextSaturday = startOfMonth.add(add_days, "days");
  let nextSaturdayDate = nextSaturday.toDate();
  if (tool == "day") {
    previousSundayDate = new Date();
    nextSaturdayDate = new Date();
  }

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};
const MemberProfile = () => {
  const member_id = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [pods, setPods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [eventsList, setEventList] = useState([]);
  const [memberNote, setMemberNote] = useState();
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));
  const [isCalenderLoading, setIsCalenderLoading] = useState(true);
  const [defaultTimeValue, setDefaultTimeValue] = useState("Asia/Karachi");
  const [breadCrumbMenu, setBreadCrumbMenu] = useState([
    {
      title: "Members",
      navigation: `/member`,
      active: false,
    },

    {
      title: "Member Profile",
      navigation: null,
      active: true,
    },
  ]);
  const podsList = async () => {
    const result = await memberPodsListing(member_id.id);
    if (result.code == 200) {
      setPods(result.rooms);
      setDefaultTimeValue(result.time_zone);
      setEventList(result.event);
      setMemberNote(result.member);
      setIsLoading(false);
      setIsCalenderLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsCalenderLoading(false);
    }
  };

  const handleGroupNavigate = () => {
    navigate(`/member/member-groups/${member_id.id}`);
  };

  const handleNotesNavigate = () => {
    navigate(`/member/member-personal-notes/${member_id.id}`, {
      state: memberNote,
    });
  };
  const handleTeachersNavigate = () => {
    navigate(`/member/teachers/${member_id.id}`, {
      state: memberNote,
    });
  };

  useEffect(() => {
    podsList();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="col-12">
        <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
      </div>
      <div className="row member-profile mt-3">
        <div className="col-12 mb-4 ">
          <h2>Member Profile</h2>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3 member-profile-image text-center mb-5">
          <img
            className="rounded-circle ms-auto me-auto"
            height="200px"
            width="200px"
            src={
              memberNote?.profile_image === undefined ||
              memberNote?.profile_image === ""
                ? dummyImage
                : s3baseUrl + memberNote?.profile_image
            }
          />
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3 text-start d-flex mb-5">
          <div className="ms-auto me-auto text-left">
            {/* <h2>{name}</h2> */}
            <Button
              variant="contained"
              className="mt-2 small-contained-button"
              onClick={() => handleGroupNavigate()}>
              View Groups
            </Button>
            <br />
            <Button
              variant="contained"
              className="mt-2 small-contained-button"
              onClick={() => handleNotesNavigate()}>
              View Client Notes
            </Button>
            <br />
            <Button
              variant="contained"
              className="mt-2 small-contained-button"
              onClick={() => handleTeachersNavigate()}>
              View Teachers
            </Button>
            <br />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-5">
          <div className="row d-flex">
            <div className="col-6 text-center">
              <h6 className="mb-3">
                <b>Name:</b>
              </h6>
              <h6 className="mb-3">
                <b>Status:</b>
              </h6>
              <h6 className="mb-3">
                <b>Email:</b>
              </h6>
              <h6 className="mb-3">
                <b>Address:</b>
              </h6>
            </div>
            <div className="col-6">
              <h6 className="mb-3">
                {memberNote?.first_name + " " + memberNote?.last_name}
              </h6>
              <h6 className="mb-3">
                <Label
                  variant="ghost"
                  color={memberNote?.status === false ? "error" : "success"}>
                  {memberNote?.status === false ? "InActive" : "Active"}
                </Label>
              </h6>
              <h6 className="mb-3">
                <div
                  dangerouslySetInnerHTML={{
                    __html: memberNote?.email,
                  }}></div>
              </h6>
              <h6 className="mb-3">{memberNote?.street}</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-5">
          <div>
            <GeneralCalendar
              eventList={eventsList}
              setCurrentDate={setCurrentDate}
              showAddEvent={false}
              time_zone={defaultTimeValue}
              currentDate={currentDate}
              isLoading={isCalenderLoading}
              setIsLoading={setIsCalenderLoading}
              reloadList={podsList}
              calendar_title="Calendar"
            />
          </div>
        </div>
        <div className="col-12 mt-5">
          {memberNote?.program.length < 1 ? (
            <>
              <h2>Programmes</h2>
              <RecordNotFound title="Programmes" />
            </>
          ) : (
            <ProgrammeListMemberProfile programmes={memberNote?.program} />
          )}
        </div>
        {/* <div className="col-12">
          {pods.length < 1 ? (
            <>
              <h2>Pods</h2>
              <RecordNotFound title="Pods" />
            </>
          ) : (
            <PodsListMemberProfile pods={pods} />
          )}
        </div> */}
      </div>
    </div>
  );
};

export default MemberProfile;
