import React from "react";
import { TextField, Grid } from "@mui/material";

import { Icon } from "@iconify/react";

// ----------------------------------------------------------------------

export default function DropDown({
  multipleChoiceOptions,
  handleChangeInMultipleChoice,
  handelAddChoice,
  handelDelChoice,
}) {
  return (
    <>
      <Grid item md={12} xs={12}>
        {multipleChoiceOptions.map((item, index) => {
          return (
            <div className="d-flex align-items-center mb-2">
              <div className="w-100">
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  sx={{
                    width: "100%",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  // fullWidth
                  required
                  name="question_statement"
                  placeholder="Enter Option"
                  value={item}
                  onChange={(e) => handleChangeInMultipleChoice(e, index)}
                />
              </div>
              <div>
                <Icon
                  icon="fluent:add-circle-20-regular"
                  color="#3da5f4"
                  width="30"
                  height="30"
                  onClick={() => {
                    handelAddChoice(index);
                  }}
                />
              </div>
              <div>
                <Icon
                  icon="fluent:subtract-circle-28-regular"
                  color="#ff4979"
                  width="30"
                  height="30"
                  onClick={() => {
                    handelDelChoice(index);
                  }}
                />
              </div>
            </div>
          );
        })}
      </Grid>
    </>
  );
}
