import React, { useState } from "react";
import {
  FormControl,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { makeStyles } from "@mui/styles";
import { Icon } from "@iconify/react";
import { CatagoryListing } from "src/DAL/Programme/Programme";
import { useEffect } from "react";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function CatagoryFilter({
  filterData,
  handleChangeOthers,
  searchSubmitFilter,
  handleClearFilter,
}) {
  const classes = useStyles();
  const [programsList, setProgramsList] = useState([]);
  const [grandParentList, setgrandParentList] = useState([]);
  const [ParentList, setParentList] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const getProgrammesList = async () => {
    setIsLoading(true);
    let result = await CatagoryListing();
    if (result.code == 200) {
      setIsLoading(false);
      let programs = result.program.map((program) => {
        return {
          ...program,
          chip_label: program.title,
          chip_value: program._id,
        };
      });
      setProgramsList(programs);
      let grandParentCategory = result.grandParentCategory.map((program) => {
        return {
          ...program,
          chip_label: program.category_name,
          chip_value: program._id,
        };
      });
      let parentCategorys = result.parentCategory.map((program) => {
        return {
          ...program,
          chip_label: program.category_name,
          chip_value: program._id,
        };
      });
      setProgramsList(programs);
      setgrandParentList(grandParentCategory);
      setParentList(parentCategorys);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let categoryType = [
    {
      chip_label: "All",
      chip_value: "all",
    },
    {
      chip_label: "Grand Parent",
      chip_value: "grand_parent",
    },
    {
      chip_label: "Parent",
      chip_value: "parent",
    },
    {
      chip_label: "Child",
      chip_value: "child",
    },
  ];

  useEffect(() => {
    getProgrammesList();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container new-memories px-4">
        <div className="mt-3">
          <MUIAutocomplete
            inputLabel="Programme"
            selectedOption={filterData.program}
            setSelectedOption={(e) => {
              handleChangeOthers(e, "program");
            }}
            optionsList={programsList}
            autoComplete="new-password"
            name="title"
          />
        </div>
        <div className="mt-3">
          <MUIAutocomplete
            inputLabel="Catagory Type"
            selectedOption={filterData.category_type}
            setSelectedOption={(e) => {
              handleChangeOthers(e, "category_type");
            }}
            optionsList={categoryType}
            autoComplete="new-password"
            name="chip_label"
          />
        </div>
        {filterData.category_type?.chip_value !== "grand_parent" && (
          <div className="mt-3">
            <MUIAutocomplete
              inputLabel="Grand Parent Catagory"
              selectedOption={filterData.grandParentCategory}
              setSelectedOption={(e) => {
                handleChangeOthers(e, "grandParentCategory");
              }}
              optionsList={grandParentList}
              autoComplete="new-password"
              name="category_name"
            />
          </div>
        )}
        {filterData.category_type?.chip_value !== "grand_parent" &&
          filterData.category_type?.chip_value !== "parent" && (
            <div className="mt-3">
              <MUIAutocomplete
                inputLabel="Parent Catagory"
                selectedOption={filterData.parentCategory}
                setSelectedOption={(e) => {
                  handleChangeOthers(e, "parentCategory");
                }}
                optionsList={ParentList}
                autoComplete="new-password"
                name="category_name"
              />
            </div>
          )}
        <div className="mt-3">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={filterData.status}
              label="Status *"
              onChange={(e) => {
                handleChangeOthers(e.target.value, "status");
              }}
            >
              <MenuItem value="">None</MenuItem>;
              <MenuItem value={true}>Active</MenuItem>;
              <MenuItem value={false}>Inactive</MenuItem>;
            </Select>
          </FormControl>
        </div>
        <Box sx={{ py: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={searchSubmitFilter}
            startIcon={
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 5 }}
              >
                <path
                  d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                  fill={get_root_value("--portal-theme-primary")}
                />
              </svg>
            }
          >
            Filter
          </Button>
        </Box>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          className="mb-3"
          onClick={handleClearFilter}
          startIcon={<Icon icon={roundClearAll} />}
        >
          Clear All
        </Button>
      </div>
    </>
  );
}
