import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { styled } from "@mui/material/styles";

import {
  Button,
  TextField,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  Box,
  Stack,
  IconButton,
  Card,
  Radio,
  Chip,
  Checkbox,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MultipleChoiceOptions from "src/components/FormTemplates/MultipleChoiceOptions";
import DropDown from "src/components/FormTemplates/DropDown";
import EditTemplate from "src/components/FormTemplates/EditUI";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { addFormApi } from "src/DAL/Forms/Forms";
import { pdfImage } from "src/assets";
import { s3baseUrl } from "src/config/config";
import { uploadImageOns3, uploadPDFOns3 } from "src/DAL/commonApi/commonApi";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const Input = styled("input")({
  display: "none",
});
export default function AddFormTemplate() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();
  const [templateTitle, setTemplateTitle] = useState("");
  const [multipleChoiceOptions, setMultipleChoiceOptions] = useState(["", ""]);
  const [rating, setRating] = useState(4);
  const [maximumRating, setMaximumRating] = useState("");
  const [checkboxText, setCheckboxText] = useState("");
  const [oldImageOther, setOldImageOther] = React.useState("");
  const [otherDocument, setOtherDocument] = React.useState();

  const [minimumRating, setMinimumRating] = useState("");
  const [questionsToSend, setQuestionsToSend] = useState([]);
  const [editShow, setEditShow] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [goToSaveTemplate, setGoToSaveTemplate] = useState(true);
  const [answerGeniusTitle, setAnswerGeniusTitle] = useState("");
  const [saveTemplate, setSaveTemplate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [inputs, setInputs] = React.useState({
    question_type: "multiple_choice",
    question_statement: "",
    is_required: false,
    otherDocument: "",
    order: 1,
  });
  const answerGenius = [
    {
      title: "Agree-Disagree",
      data: ["Strongly agree", "Agree", "Disagree", "Strongly disagree"],
    },
    {
      title: "Exceeds-Meets",
      data: ["Exceeds", "Meets", "Need Improvement"],
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputs.question_type == "multiple_choice") {
      const updatedList = [...questionsToSend];

      const updatedPrograms = questionsToSend.filter(
        (program) => program.order < inputs.order
      );
      // updatedList.push({
      //   title: inputs.question_statement,
      //   attachment: "",
      //   required: inputs.is_required,
      //   order: inputs.order,
      //   type: inputs.question_type,
      //   options: multipleChoiceOptions,
      //   options_count: multipleChoiceOptions.length,
      // });
      updatedPrograms.push({
        title: inputs.question_statement,
        attachment: "",
        required: inputs.is_required,
        order: inputs.order,
        type: inputs.question_type,
        options: multipleChoiceOptions,
        options_count: multipleChoiceOptions.length,
      });
      questionsToSend
        .filter((program) => program.order >= inputs.order)
        .forEach((program) => {
          program.order++;
          updatedPrograms.push(program);
        });
      editShow.push({ display: false });
      setQuestionsToSend(updatedPrograms);
      setInputs((inputs) => {
        return {
          ...inputs,
          ["question_type"]: "multiple_choice",
          ["question_statement"]: "",
          ["is_required"]: false,
          ["otherDocument"]: "",
          ["order"]: updatedPrograms.length + 1,
        };
      });
      setMultipleChoiceOptions(["", ""]);
    }
    if (inputs.question_type == "multiple_select") {
      const updatedList = [...questionsToSend];
      const updatedPrograms = questionsToSend.filter(
        (program) => program.order < inputs.order
      );
      // updatedList.push({
      //   title: inputs.question_statement,
      //   attachment: "",
      //   required: inputs.is_required,
      //   order: inputs.order,
      //   type: inputs.question_type,
      //   options: multipleChoiceOptions,
      //   options_count: multipleChoiceOptions.length,
      // });
      updatedPrograms.push({
        title: inputs.question_statement,
        attachment: "",
        required: inputs.is_required,
        order: inputs.order,
        type: inputs.question_type,
        options: multipleChoiceOptions,
        options_count: multipleChoiceOptions.length,
      });
      questionsToSend
        .filter((program) => program.order >= inputs.order)
        .forEach((program) => {
          program.order++;
          updatedPrograms.push(program);
        });
      editShow.push({ display: false });
      setQuestionsToSend(updatedPrograms);
      setInputs((inputs) => {
        return {
          ...inputs,
          ["question_type"]: "multiple_choice",
          ["question_statement"]: "",
          ["is_required"]: false,
          ["otherDocument"]: "",
          ["order"]: updatedPrograms.length + 1,
        };
      });
      setMultipleChoiceOptions(["", ""]);
    }
    if (inputs.question_type == "dropdown") {
      const updatedList = [...questionsToSend];
      const updatedPrograms = questionsToSend.filter(
        (program) => program.order < inputs.order
      );
      // updatedList.push({
      //   title: inputs.question_statement,
      //   attachment: "",
      //   required: inputs.is_required,
      //   order: inputs.order,
      //   type: inputs.question_type,
      //   options: multipleChoiceOptions,
      //   options_count: multipleChoiceOptions.length,
      // });
      updatedPrograms.push({
        title: inputs.question_statement,
        attachment: "",
        required: inputs.is_required,
        order: inputs.order,
        type: inputs.question_type,
        options: multipleChoiceOptions,
        options_count: multipleChoiceOptions.length,
      });
      questionsToSend
        .filter((program) => program.order >= inputs.order)
        .forEach((program) => {
          program.order++;
          updatedPrograms.push(program);
        });
      editShow.push({ display: false });
      setQuestionsToSend(updatedPrograms);
      setInputs((inputs) => {
        return {
          ...inputs,
          ["question_type"]: "multiple_choice",
          ["question_statement"]: "",
          ["is_required"]: false,
          ["otherDocument"]: "",
          ["order"]: updatedPrograms.length + 1,
        };
      });
      setMultipleChoiceOptions(["", ""]);
    }

    if (inputs.question_type == "textbox") {
      const updatedList = [...questionsToSend];

      const updatedPrograms = questionsToSend.filter(
        (program) => program.order < inputs.order
      );
      updatedPrograms.push({
        title: inputs.question_statement,
        required: inputs.is_required,
        order: inputs.order,
        attachment: "",
        type: inputs.question_type,
        options: [],
        options_count: 0,
      });
      questionsToSend
        .filter((program) => program.order >= inputs.order)
        .forEach((program) => {
          program.order++;
          updatedPrograms.push(program);
        });
      // updatedList.push({
      //   title: inputs.question_statement,
      //   required: inputs.is_required,
      //   order: inputs.order,
      //   attachment: "",
      //   type: inputs.question_type,
      //   options: [],
      //   options_count: 0,
      // });
      editShow.push({ display: false });
      setQuestionsToSend(updatedPrograms);
      setInputs((inputs) => {
        return {
          ...inputs,
          ["question_type"]: "multiple_choice",
          ["question_statement"]: "",
          ["is_required"]: false,
          ["otherDocument"]: "",
          ["order"]: updatedPrograms.length + 1,
        };
      });
    }
    if (inputs.question_type == "checkbox") {
      const updatedList = [...questionsToSend];
      const updatedPrograms = questionsToSend.filter(
        (program) => program.order < inputs.order
      );
      // updatedList.push({
      //   title: inputs.question_statement,
      //   required: inputs.is_required,
      //   attachment: inputs.otherDocument,
      //   order: inputs.order,
      //   type: inputs.question_type,
      //   options: [],
      //   options_count: 0,
      // });
      updatedPrograms.push({
        title: inputs.question_statement,
        required: inputs.is_required,
        attachment: inputs.otherDocument,
        order: inputs.order,
        type: inputs.question_type,
        options: [],
        options_count: 0,
      });
      questionsToSend
        .filter((program) => program.order >= inputs.order)
        .forEach((program) => {
          program.order++;
          updatedPrograms.push(program);
        });
      editShow.push({ display: false });
      setQuestionsToSend(updatedPrograms);

      setInputs((inputs) => {
        return {
          ...inputs,
          ["question_type"]: "multiple_choice",
          ["question_statement"]: "",
          ["is_required"]: false,
          ["otherDocument"]: "",
          ["order"]: updatedPrograms.length + 1,
        };
      });
      setMultipleChoiceOptions(["", ""]);
    }
    setAnswerGeniusTitle("");
    setSaveTemplate(true);
    // questionsToSend.sort((a, b) => a.order - b.order);
  };

  const handleChangeOrder = (event) => {
    const name = event.target.name;
    const value = parseInt(event.target.value);

    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleCheckbox = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setCheckboxText(value);
  };
  const fileChangedHandlerOther = async (e) => {
    // setOtherDocument(URL.createObjectURL(e.target.files[0]));
    setUploading(true);
    const formData = new FormData();
    formData.append("image", e.target.files[0]);

    const imageUpload = await uploadPDFOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        ["otherDocument"]: imageUpload.image_path,
      });
      setUploading(false);
      // setOldImageOther(imageUpload.image_path);
      // console.log(imageUpload, "imageUploadimageUpload");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeInMultipleChoice = (e, index) => {
    if (e.target.value[0] === " ") {
      return;
    }
    multipleChoiceOptions[index] = e.target.value;
    setMultipleChoiceOptions([...multipleChoiceOptions]);
  };
  const handelAddChoice = (index) => {
    let list = multipleChoiceOptions;
    list.splice(index + 1, 0, "");
    setMultipleChoiceOptions([...list]);
  };

  const handelDelChoice = (index) => {
    if (multipleChoiceOptions.length === 2) {
      return;
    }
    let list = multipleChoiceOptions;
    list.splice(index, 1);
    setMultipleChoiceOptions([...list]);
  };
  const handelDelQuestion = () => {
    let index = deleteData;
    // console.log(index, "kjsdjk");
    let list = questionsToSend;
    let list2 = editShow;
    list2.splice(index, 1);
    list.splice(index, 1);
    setEditShow([...list2]);
    setQuestionsToSend([...list]);

    setOpenDelete(false);
  };
  const handleAgreeDelete = (value) => {
    // console.log(value, "value");
    setDeleteData(value);
    setOpenDelete(true);
  };
  const handelEditQuestions = (index) => {
    editShow[index].display = true;
    setEditShow([...editShow]);
    setGoToSaveTemplate(false);
    setSaveTemplate(true);
  };
  const handelcloseQuestionsEntry = () => {
    setInputs((values) => ({
      ...values,
      ["question_type"]: "multiple_choice",
      ["question_statement"]: "",
    }));
    setMinimumRating("");
    setMaximumRating("");
    setMultipleChoiceOptions(["", ""]);
    setAnswerGeniusTitle("");
    // setGoToSaveTemplate(true);
    setRating("");
    setSaveTemplate(true);
  };
  const handleChangeAnswerGenius = (event) => {
    setAnswerGeniusTitle(event.target.value);
    answerGenius.filter((ans) => {
      if (ans.title == event.target.value) {
        // console.log(ans, "sdfklks");
        setMultipleChoiceOptions(ans.data);
      }
    });
  };

  const handelSendDataToApi = async (event) => {
    const newArray = questionsToSend.map((item) => {
      let newItem = {
        question_statement: item.title,
        is_required: item.required,
        question_type: item.type,
      };

      // Only include options if type is not "checkbox" or "textbox"
      if (item.type !== "checkbox" && item.type !== "textbox") {
        newItem.values = item.options;
      }
      if (item.type == "checkbox") {
        newItem.attachment = item.attachment;
      }

      return newItem;
    });
    let postData = {
      form_title: templateTitle,
      // receiver_type: templateType,
      // description: templateDescription,
      form_questions: newArray,
    };
    console.log(postData, "postData========");
    console.log(questionsToSend, "questionsToSend========");

    setIsLoading(true);
    try {
      const res = await addFormApi(postData);
      if (res.code === 200) {
        navigate(-1);

        enqueueSnackbar(res.message, { variant: "success" });
      } else {
        setIsLoading(false);
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  useEffect(() => {
    let count = 0;
    editShow.map((item, index) => {
      if (item.display) {
        count--;
      } else {
        count++;
      }
    });
    if (count == editShow.length) {
      setGoToSaveTemplate(true);
    } else {
      setGoToSaveTemplate(false);
    }
  }, [editShow]);
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <Container>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handelDelQuestion}
      />
      <div className="row mb-2">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-3"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="col-12">
          <h2>Add Template</h2>
        </div>
      </div>

      <Box
        sx={{ width: { sm: "100%", md: "100%" }, margin: "auto" }}
        margin={0}>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 0, sm: 0, md: 2 }}>
          <Grid item md={6} xs={12}>
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              sx={{ width: "100%" }}
              // fullWidth
              required
              name="title"
              value={templateTitle}
              onChange={(e) => {
                if (e.target.value[0] === " ") {
                  return;
                }
                setTemplateTitle(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Stack
        direction="column"
        alignItems="center"
        spacing={1.5}
        justifyContent="space-between"
        mb={4.5}
        mt={2}>
        {questionsToSend &&
          questionsToSend.map((item, index) => {
            return !editShow[index].display ? (
              <Card
                className="w-100"
                sx={{
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  mb: 1,
                  width: { sm: "100%", md: "100%" },
                  margin: "auto",
                  p: 2,
                  backgroundColor: "#f5f5f2",
                  position: "relative",
                }}>
                {item.required && (
                  <Chip
                    label={"Required"}
                    variant="contained"
                    color={"error"}
                    sx={{
                      fontSize: "10px",
                      height: "auto",
                      width: "auto",
                      position: "absolute",
                      top: 8,
                      left: 16,
                    }}
                  />
                )}
                <Box sx={{ width: "100%", margin: "auto" }} margin={0}>
                  <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 0, sm: 0, md: 2 }}
                    mt={1}>
                    <Grid item md={12} xs={12}>
                      <div className=" "></div>
                      <div className="d-flex align-items-center mb-2">
                        <div className="">
                          <Typography
                            sx={{ fontWeight: "900", fontSize: 16, mr: 1 }}>
                            Q{index + 1}.
                          </Typography>
                        </div>
                        <div className="d-flex">
                          {item?.type == "checkbox" && <Checkbox disabled />}
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: 14,
                              mr: 1,
                              marginTop:
                                item?.type == "checkbox" ? "8px" : "inherit",
                            }}>
                            {item?.title}
                          </Typography>
                        </div>
                      </div>
                    </Grid>

                    {item?.type == "multiple_choice" ? (
                      <Grid item md={12} xs={12}>
                        {item?.options.map((item) => {
                          return (
                            <Grid item md={12}>
                              <div className="d-flex align-items-center mb-2">
                                <div>
                                  <Radio disabled />
                                </div>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontSize: 14,
                                    mr: 1,
                                  }}>
                                  {item}
                                </Typography>
                              </div>
                            </Grid>
                          );
                        })}
                        <div className="w-100 mt-2 d-flex justify-content-end">
                          <Button
                            variant="outlined"
                            sx={{ mr: 1 }}
                            onClick={() => {
                              handelEditQuestions(index);
                            }}>
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              handleAgreeDelete(index);
                            }}>
                            Delete
                          </Button>
                        </div>
                      </Grid>
                    ) : item?.type == "multiple_select" ? (
                      <Grid item md={12} xs={12}>
                        {item?.options.map((item) => {
                          return (
                            <Grid item md={12}>
                              <div className="d-flex align-items-center mb-2">
                                <div>
                                  <Radio disabled />
                                </div>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontSize: 14,
                                    mr: 1,
                                  }}>
                                  {item}
                                </Typography>
                              </div>
                            </Grid>
                          );
                        })}
                        <div className="w-100 mt-2 d-flex justify-content-end">
                          <Button
                            variant="outlined"
                            sx={{ mr: 1 }}
                            onClick={() => {
                              handelEditQuestions(index);
                            }}>
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              handleAgreeDelete(index);
                            }}>
                            Delete
                          </Button>
                        </div>
                      </Grid>
                    ) : item?.type == "textbox" ? (
                      <Grid item md={12} xs={12}>
                        <TextField
                          id="outlined-multiline-static"
                          label="Answer"
                          multiline
                          disabled
                          rows={4}
                          sx={{ width: "100%" }}
                        />
                        <div className="w-100 mt-2 d-flex justify-content-end">
                          <Button
                            variant="outlined"
                            sx={{ mr: 1 }}
                            onClick={() => {
                              handelEditQuestions(index);
                            }}>
                            Edit
                          </Button>{" "}
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              handleAgreeDelete(index);
                            }}>
                            Delete
                          </Button>
                        </div>
                      </Grid>
                    ) : item?.type == "dropdown" ? (
                      <Grid item md={12} xs={12}>
                        {item?.options.map((item, index) => {
                          return (
                            <Grid item md={12}>
                              <div className="d-flex align-items-center mb-2">
                                <div className="mt-3">
                                  <p style={{ fontWeight: "bold" }}>{`${
                                    index + 1
                                  }.`}</p>
                                </div>

                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontSize: 14,
                                    mr: 1,
                                    ml: 2,
                                  }}>
                                  {item}
                                </Typography>
                              </div>
                            </Grid>
                          );
                        })}
                        <div className="w-100 mt-2 d-flex justify-content-end">
                          <Button
                            variant="outlined"
                            sx={{ mr: 1 }}
                            onClick={() => {
                              handelEditQuestions(index);
                            }}>
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              handleAgreeDelete(index);
                            }}>
                            Delete
                          </Button>
                        </div>
                      </Grid>
                    ) : (
                      item?.type == "checkbox" && (
                        <Grid item md={12} xs={12}>
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row w-100 div-style ms-0 pt-0">
                              <div className="col-5"></div>
                              <div className="col-3">
                                {item.attachment && (
                                  <img src={pdfImage} height="50" />
                                )}
                              </div>

                              <div className="col-4 text-end pt-2 link-button mt-3">
                                {item.attachment && (
                                  <a
                                    href={s3baseUrl + item.attachment}
                                    target="_blank"
                                    className="me-2 small-contained-button">
                                    View File
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="w-100 mt-2 d-flex justify-content-end">
                            <Button
                              variant="outlined"
                              sx={{ mr: 1 }}
                              onClick={() => {
                                handelEditQuestions(index);
                              }}>
                              Edit
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => {
                                handleAgreeDelete(index);
                              }}>
                              Delete
                            </Button>
                          </div>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              </Card>
            ) : (
              <EditTemplate
                questionsToSend={questionsToSend}
                index={index}
                answerGenius={answerGenius}
                setQuestionsToSend={setQuestionsToSend}
                editShow={editShow}
                setGoToSaveTemplate={setGoToSaveTemplate}
                setEditShow={setEditShow}
              />
            );
          })}
        {!saveTemplate ? (
          <Card
            className="w-100"
            sx={{
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              mb: 1,
              width: { sm: "100%", md: "100%" },
              margin: "auto",
              p: 2,
            }}>
            <form onSubmit={handleSubmit} autoComplete="off">
              <Box sx={{ width: "100%", margin: "auto" }} margin={0}>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 0, sm: 0, md: 2 }}
                  mt={1}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Question Statement"
                      variant="outlined"
                      sx={{ width: "100%" }}
                      // fullWidth
                      required
                      name="question_statement"
                      value={inputs.question_statement}
                      onChange={(event) => {
                        if (event.target.value[0] === " ") {
                          return;
                        }
                        setInputs((values) => ({
                          ...values,
                          [event.target.name]: event.target.value,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item md={4.5} xs={12}>
                    <FormControl sx={{ mb: 0, width: "100%" }} required>
                      <InputLabel id="demo-simple-selec-label" size="small">
                        Question Type
                      </InputLabel>
                      <Select
                        size="small"
                        required
                        labelId="demo-simple-selec-label"
                        id="demo-simple-select"
                        name="question_type"
                        value={inputs?.question_type}
                        label="Question Type"
                        onChange={handleChange}>
                        <MenuItem value="multiple_choice">
                          Multiple Choice (single select)
                        </MenuItem>
                        <MenuItem value="multiple_select">
                          Multiple Choice (Multiple select)
                        </MenuItem>
                        <MenuItem value="textbox">Text</MenuItem>
                        <MenuItem value="checkbox">Checkbox</MenuItem>
                        <MenuItem value="dropdown">Dropdown</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <FormControl sx={{ mb: 0, width: "100%" }} required>
                      <InputLabel id="demo-simple-selec-label1" size="small">
                        Is it Required?
                      </InputLabel>
                      <Select
                        size="small"
                        required
                        labelId="demo-simple-selec-label1"
                        id="demo-simple-select"
                        name="is_required"
                        value={inputs?.is_required}
                        label="Is it Requird"
                        onChange={handleChange}>
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <Tooltip
                      title={`Order cannot be 0 or greater then ${
                        questionsToSend.length + 1
                      }`}>
                      <TextField
                        id="outlined-basic"
                        label="Order"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        size="small"
                        type="number"
                        required
                        name="order"
                        onWheel={(event) => event.target.blur()}
                        value={inputs.order}
                        onChange={(event) => {
                          let newValue = parseInt(event.target.value);
                          if (isNaN(newValue)) {
                            newValue = ""; // If it's not a valid number, set it to an empty string
                          } else if (newValue < 1) {
                            newValue = ""; // If less than 1, set it to 1
                          } else if (newValue > questionsToSend.length + 1) {
                            newValue = ""; // If greater than array.length + 1, set it to array.length + 1
                          }
                          setInputs((values) => ({
                            ...values,
                            [event.target.name]: newValue,
                          }));
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item md={12} xs={12} sx={{ mb: 1 }}>
                    {/* {inputs.question_type != "checkbox" && ( */}
                    <hr className="mb-0 " />
                    {/* )} */}
                  </Grid>
                  {inputs?.question_type == "multiple_choice" ? (
                    <MultipleChoiceOptions
                      answerGeniusTitle={answerGeniusTitle}
                      handleChangeAnswerGenius={handleChangeAnswerGenius}
                      answerGenius={answerGenius}
                      multipleChoiceOptions={multipleChoiceOptions}
                      handleChangeInMultipleChoice={
                        handleChangeInMultipleChoice
                      }
                      handelAddChoice={handelAddChoice}
                      handelDelChoice={handelDelChoice}
                    />
                  ) : inputs?.question_type == "multiple_select" ? (
                    <MultipleChoiceOptions
                      answerGeniusTitle={answerGeniusTitle}
                      handleChangeAnswerGenius={handleChangeAnswerGenius}
                      answerGenius={answerGenius}
                      multipleChoiceOptions={multipleChoiceOptions}
                      handleChangeInMultipleChoice={
                        handleChangeInMultipleChoice
                      }
                      handelAddChoice={handelAddChoice}
                      handelDelChoice={handelDelChoice}
                    />
                  ) : inputs?.question_type == "dropdown" ? (
                    <DropDown
                      answerGeniusTitle={answerGeniusTitle}
                      handleChangeAnswerGenius={handleChangeAnswerGenius}
                      answerGenius={answerGenius}
                      multipleChoiceOptions={multipleChoiceOptions}
                      handleChangeInMultipleChoice={
                        handleChangeInMultipleChoice
                      }
                      handelAddChoice={handelAddChoice}
                      handelDelChoice={handelDelChoice}
                    />
                  ) : inputs?.question_type == "checkbox" ? (
                    <Grid item md={12} xs={12}>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row w-100 div-style ms-0 pt-0">
                          <div className="col-5">
                            <p className="">Upload PDF</p>
                          </div>
                          <div className="col-2">
                            {inputs.otherDocument && (
                              <img src={pdfImage} height="50" />
                            )}
                          </div>

                          <div className="col-5 text-end pt-2 link-button">
                            {inputs.otherDocument && (
                              <a
                                href={s3baseUrl + inputs.otherDocument}
                                target="_blank"
                                className="me-2 small-contained-button">
                                View File
                              </a>
                            )}
                            <label htmlFor="contained-button-file-other">
                              <Input
                                accept=".pdf"
                                id="contained-button-file-other"
                                multiple
                                type="file"
                                name="otherDocument"
                                onChange={fileChangedHandlerOther}
                              />

                              <Button
                                className="small-contained-button"
                                startIcon={<FileUploadIcon />}
                                component="span">
                                {uploading ? "Uploading..." : "Upload"}
                              </Button>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ) : (
                    // <></>
                    <Grid item md={12} xs={12}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Answer"
                        multiline
                        disabled
                        rows={6}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                  )}

                  <Grid item md={12} xs={12}>
                    <hr className="mb-0 " />
                  </Grid>
                </Grid>

                <div className="col-12 mt-3 text-end">
                  <button className="small-contained-button" type="submit">
                    <Icon
                      style={{ marginRight: "5px", width: 20, height: 20 }}
                      icon="eva:plus-fill"
                    />
                    Save
                  </button>
                  <button
                    className="small-contained-button ms-2"
                    onClick={() => handelcloseQuestionsEntry()}>
                    <Icon
                      style={{ marginRight: "5px", width: 20, height: 20 }}
                      icon="ic:twotone-close-fullscreen"
                    />
                    Close
                  </button>
                </div>
              </Box>
            </form>
          </Card>
        ) : (
          goToSaveTemplate && (
            <div className="w-100 mt-3 text-end">
              <button
                className="small-contained-button"
                onClick={() => setSaveTemplate(false)}>
                <Icon
                  style={{ marginRight: "5px", width: 20, height: 20 }}
                  icon="eva:plus-fill"
                />
                Add Question
              </button>
              <button
                className="small-contained-button ms-2"
                onClick={() => {
                  handelSendDataToApi();
                }}>
                <Icon
                  style={{ marginRight: "5px", width: 20, height: 20 }}
                  icon="eva:plus-fill"
                />
                Save Template
              </button>
            </div>
          )
        )}
      </Stack>
    </Container>
  );
}
