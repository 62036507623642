import { TextField, Tooltip } from "@mui/material";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TimePicker } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";

export default function TimeSlots({ setFieldsArray, fieldsArray }) {
  const handleAdd = (index) => {
    const newFieldsArray = [...fieldsArray];
    newFieldsArray.splice(index + 1, 0, {
      start_time: dayjs(new Date()).$d,
      end_time: dayjs(new Date()).$d,
      total_seats: 1,
    });
    setFieldsArray(newFieldsArray);
  };

  const handleDelete = (index) => {
    const newFieldsArray = [...fieldsArray];
    newFieldsArray.splice(index, 1);
    setFieldsArray(newFieldsArray);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newFieldsArray = [...fieldsArray];
    newFieldsArray[index][name] = value;
    setFieldsArray(newFieldsArray);
  };

  const handleChangeDate = (name, value, index) => {
    const newFieldsArray = [...fieldsArray];
    newFieldsArray[index][name] = value.$d;
    setFieldsArray(newFieldsArray);
    console.log(moment(value.$d).format("HH:mm"), "===heloooo");
    // moment(value).format("HH:mm"),
    // end_time: moment(state.end_time).format("HH:mm"),
  };

  return (
    <div>
      {fieldsArray.map((inputs, index) => (
        <div className="card mx-2 mt-1 p-2" key={index}>
          <div className="row">
            <span className="col-12 text-end mb-1">
              {fieldsArray.length > 1 && (
                <Tooltip title="Remove">
                  <RemoveCircleOutlineIcon
                    onClick={() => handleDelete(index)}
                    className="diary-icon-remove"
                  />
                </Tooltip>
              )}
              <Tooltip title="Add">
                <AddCircleOutlineIcon
                  onClick={() => handleAdd(index)}
                  className="diary-icon-add"
                />
              </Tooltip>
            </span>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                className="w-100">
                <TimePicker
                  label="Start Time"
                  name="start_time"
                  value={inputs.start_time}
                  sx={{ color: "#fff" }}
                  className="mt-3 "
                  onChange={(value) =>
                    handleChangeDate("start_time", value, index)
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth required />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                className="w-100">
                <TimePicker
                  label="End Time"
                  name="end_time"
                  value={inputs.end_time}
                  sx={{ color: "#fff", mt: 14 }}
                  onChange={(value) =>
                    handleChangeDate("end_time", value, index)
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth required />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Total Seats"
                variant="outlined"
                fullWidth
                required
                type="number"
                name="total_seats"
                onWheel={(event) => event.target.blur()}
                value={inputs.total_seats}
                onChange={(e) => handleChange(e, index)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
