import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  deletePaymentPlanApi,
  paymentPlansListApi,
} from "src/DAL/WebsitePages/paymentPlan";
import { page_detail_by_id } from "src/DAL/WebsitePages/websitepages";
import { s3baseUrl } from "src/config/config";
import CustomMUITable from "src/components/CustomMUITable";
import ActiveLastBreadcrumb from "src/components/ActivePaths";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "plan_title", label: "Title", alignRight: false },
  { id: "planImage", label: "Image", alignRight: false, type: "thumbnail" },

  {
    id: "is_free",
    label: "Plan",
    alignRight: false,
    className: "text-capitalize",
  },
  {
    id: "payment_method_preferance",
    label: "Payment Method",
    alignRight: false,
    className: "text-capitalize",
  },
  {
    id: "booked",
    label: "Booked Seats",
    renderData: (row) => {
      return row.timeslot && row.timeslot.length > 0 ? (
        <>
          <Tooltip
            title={
              <>
                <div className="d-flex mb-2">
                  <span> Time Slots</span>
                  <span className="ms-4"> Seats</span>
                </div>
                {row.timeslot?.map((program, i) => {
                  return (
                    <>
                      <div className="d-flex">
                        <span>
                          {" "}
                          {`${program.start_time} - ${program.end_time}`}
                        </span>
                        <span className="ms-4">
                          {" "}
                          {`${program.total_seats}`}
                        </span>
                      </div>
                    </>
                  );
                })}
              </>
            }>
            <p className="mb-0">{`${row.booked}/${row.allSeats}`}</p>
          </Tooltip>
        </>
      ) : (
        <>
          <p className="mb-0">N/A</p>
        </>
      );
    },
    alignRight: false,
  },

  { id: "plan_status", label: "Status", alignRight: false, type: "row_status" },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function BookingPaymentPlans() {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const classes = useStyles();
  const [moduleData, setModuleData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [paymentPlan, setPaymentPlan] = useState([]);
  const [pageData, setPageData] = useState({});
  const is_website_pages = window.location.pathname.includes("website-pages");
  const module_actual_name = "payment_plans";
  const getConsultantListing = async () => {
    const result = await paymentPlansListApi(
      params.page_id ? params.page_id : ""
    );
    if (result.code === 200) {
      const data = result.payment_plan.map((plan) => {
        const totalSeats = Array.isArray(plan.timeslot)
          ? plan.timeslot.reduce((acc, program) => acc + program.total_seats, 0)
          : 0;
        const reserved = Array.isArray(plan.timeslot)
          ? plan.timeslot.reduce(
              (acc, program) => acc + program.reserved_seats,
              0
            )
          : 0;

        return {
          ...plan,
          is_free:
            plan.is_plan_free == true
              ? "Free"
              : `Paid ( ${
                  plan.payment_access === "recursion"
                    ? "Recurring"
                    : plan.payment_access
                } ${
                  plan.payment_access == "onetime"
                    ? ""
                    : plan.product?.name
                    ? `| ${plan.product.name} `
                    : ""
                })`,
          productName: plan.product?.name ? plan.product.name : "N/A",
          payment_method_preferance: plan.payment_method_preferance
            ? plan.payment_method_preferance
            : "N/A",
          planImage: {
            src: s3baseUrl + plan.plan_image,
            alt: plan.plan_title,
          },

          time_period_interval_param: `${plan.time_period_interval}  ${
            plan.payment_access == "recursion" && plan.plan_type
              ? plan.plan_type
              : ""
          }`,
          booked: isNaN(reserved) ? 0 : reserved,
          allSeats: totalSeats,
          form_template: plan.form_template,
          planPrice:
            plan.is_plan_free == true
              ? "£0"
              : plan.plan_price
              ? plan.plan_currency == "usd"
                ? "$" + plan.plan_price
                : plan.plan_currency == "gbp"
                ? "£" + plan.plan_price
                : plan.plan_currency == "eur"
                ? "€" + plan.plan_price
                : "" + plan.plan_price
              : "£0",
        };
      });

      setPaymentPlan(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleEdit = (value) => {
    if (params.page_id) {
      if (is_website_pages) {
        navigate(
          `/website-pages/${params.page_id}/booking-payment-plans/edit-plan/${value._id}`,
          {
            state: {
              data: value,
              page_info: pageData,
            },
          }
        );
      } else {
        navigate(
          `/template-pages/${params.page_id}/booking-payment-plans/edit-plan/${value._id}`,
          {
            state: {
              data: value,
              page_info: pageData,
            },
          }
        );
      }
    } else {
      navigate(`/payment-plans/edit-plan/${value._id}`);
    }
  };
  const handleSubscriber = (value) => {
    console.log(value, "subscriber func", params.page_id, is_website_pages);
    if (params.page_id) {
      if (is_website_pages) {
        navigate(
          `/website-pages/${params.page_id}/booking-subscriber/${value._id}`,
          {
            state: {
              data: value,
              page_info: pageData,
            },
          }
        );
      } else {
        navigate(
          `/template-pages/${params.page_id}/booking-subscriber/${value._id}`,
          {
            state: {
              data: value,
              page_info: pageData,
            },
          }
        );
      }
    } else {
      console.log("called else");
      navigate(`/payment-plans/edit-plan/${value._id}`);
    }
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const getPageDetail = async () => {
    setIsLoading(true);
    const result = await page_detail_by_id(params.page_id);
    if (result.code == 200) {
      handlePageDetail(result.sale_page);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deletePaymentPlanApi(deleteDoc._id);
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleManageEventsAccess = (value) => {
    if (is_website_pages) {
      navigate(
        `/website-pages/${params.page_id}/payment-plans/${value._id}/manage-events-access`
      );
    } else {
      navigate(
        `/template-pages/${params.page_id}/payment-plans/${value._id}/manage-events-access`
      );
    }
  };
  const handleNavigate = () => {
    if (params.page_id) {
      if (is_website_pages) {
        navigate(
          `/website-pages/${params.page_id}/booking-payment-plans/add-plan`,
          {
            state: pageData,
          }
        );
      } else {
        navigate(
          `/template-pages/${params.page_id}/booking-payment-plans/add-plan`,
          {
            state: pageData,
          }
        );
      }
    } else {
      navigate(`/payment-plans/add-plan`);
    }
  };

  const handlePageDetail = (data) => {
    setPageData(data);
    const get_module_info = data.module_info.filter(
      (item) => item.module_actual_name == module_actual_name
    )[0];
    setModuleData(get_module_info.module_replica_info);
  };

  useEffect(() => {
    getConsultantListing();
    if (params.page_id) {
      if (state) {
        handlePageDetail(state);
      } else {
        getPageDetail();
      }
    }
  }, [params.page_id]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Subscribers",
      icon: "akar-icons:edit",
      handleClick: handleSubscriber,
    },
  ];
  let breadCrumbMenu = [
    {
      title: is_website_pages ? "pages" : "Template Pages",
      navigation: is_website_pages ? `/website-pages` : `/template-pages`,
      active: false,
    },

    {
      title: pageData.sale_page_title,
      navigation: null,
      active: true,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        {params.page_id && (
          <div className="row">
            <div className="col-12 display-flex mb-4">
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </div>
          </div>
        )}
        <div className="row mb-5">
          <div className="col-lg-8 col-sm-12">
            <h2>
              {moduleData?.list_page_heading
                ? moduleData.list_page_heading
                : "Payment Plans"}
            </h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              {moduleData?.add_button_text
                ? moduleData.add_button_text
                : "Add Payment Plan"}
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={paymentPlan}
          className="card-with-background text-nowrap"
          MENU_OPTIONS={MENU_OPTIONS}
          pagePagination={true}
        />
      </div>
    </>
  );
}
