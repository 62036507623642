import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { programmeListing } from "src/DAL/Programme/Programme";
import { productListApi } from "src/DAL/member/member";
import { AddPaymentTemplateApi } from "src/DAL/PaymentTemplate/paymentTemplateApi";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddPaymentTemplate() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = React.useState([]);
  const [programmes, setProgrammes] = React.useState([]);
  const [installmentAmount, setInstallmentAmount] = useState("");

  const [inputs, setInputs] = React.useState({
    title: "",
    currency: "gbp",
    total_amount: "",
    vat_number: "",
    transaction_note: "",
    template_type: "",
    payment_request_type: "onetime",
    product: "",
    status: "true",
    showOnConsultant: "no",
    programme: "",
    initial_amount: "",
    month: "",
    installment_amount: "",
    no_of_installments: "",
  });

  const productList = async () => {
    setIsLoading(true);
    let result = await productListApi();
    if (result.code == 200) {
      setProducts(result.product);
      setIsLoading(false);
      setInputs({
        ...inputs,
        ["product"]: result.product[0]._id,
      });
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };
  const programmeList = async () => {
    setIsLoading(true);
    let result = await programmeListing();
    if (result.code == 200) {
      setProgrammes(result.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let postData = {};
    if (inputs.payment_request_type === "onetime") {
      postData = {
        title: inputs.title,
        template_type: inputs.payment_request_type,
        total_amount: inputs.total_amount,
        currency: inputs.currency,
        vat_number: inputs.vat_number,
        short_description: inputs.transaction_note,
        program: inputs.programme,
        product: inputs.product,
      };
    } else if (inputs.payment_request_type === "recurring") {
      postData = {
        title: inputs.title,
        template_type: inputs.payment_request_type,
        total_amount: inputs.total_amount,
        initial_amount: inputs.initial_amount,
        currency: inputs.currency,
        no_of_installment: inputs.no_of_installments,
        vat_number: inputs.vat_number,
        short_description: inputs.transaction_note,
        program: inputs.programme,
        product: inputs.product,
      };
    }

    setIsLoading(true);
    const result = await AddPaymentTemplateApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  React.useEffect(() => {
    productList();
    programmeList();
  }, []);

  React.useEffect(() => {
    let installment =
      (inputs.total_amount - inputs.initial_amount) / inputs.no_of_installments;
    setInstallmentAmount(installment);
  }, [inputs.total_amount, inputs.no_of_installments, inputs.initial_amount]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Add Payment Template</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            fullWidth
            required
            name="title"
            value={inputs.title}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Currency *</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="currency"
              value={inputs.currency}
              label="currency"
              onChange={handleChange}
            >
              <MenuItem value="usd">Dollar</MenuItem>
              <MenuItem value="gbp">UK Pounds</MenuItem>
              <MenuItem value="eur">Euro</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Product</InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="product"
              value={inputs.product}
              label="product"
              onChange={handleChange}
            >
              {products.map((product) => {
                return <MenuItem value={product._id}>{product.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Programme *</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="programme"
              value={inputs.programme}
              label="Programme"
              onChange={handleChange}
              required
            >
              {programmes.map((programme) => {
                return (
                  <MenuItem value={programme._id}>{programme.title}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Payment request Type*
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="payment_request_type"
              value={inputs.payment_request_type}
              label="Payment Request Type"
              onChange={handleChange}
            >
              <MenuItem value="onetime">One Time</MenuItem>
              <MenuItem value="recurring">Recurring</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Total Amount"
            variant="outlined"
            fullWidth
            type="number"
            required
            name="total_amount"
            value={inputs.total_amount}
            onChange={handleChange}
          />
        </div>
        {inputs.payment_request_type === "recurring" && (
          <>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Initial Amount"
                variant="outlined"
                fullWidth
                type="number"
                required
                name="initial_amount"
                value={inputs.initial_amount}
                onChange={handleChange}
              />
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="No of Installments"
                variant="outlined"
                fullWidth
                required
                name="no_of_installments"
                value={inputs.no_of_installments}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Installment Amount"
                variant="outlined"
                fullWidth
                type="number"
                required
                aria-readonly
                name="installment_amount"
                value={installmentAmount}
                // onChange={handleChange}
              />
            </div>
          </>
        )}

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Vat Number"
            variant="outlined"
            fullWidth
            // type="number"
            name="vat_number"
            value={inputs.vat_number}
            onChange={handleChange}
          />
        </div>

        <div className="col-12 mt-5">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description"
              multiline
              rows={6}
              name="transaction_note"
              value={inputs.transaction_note}
              onChange={handleChange}
            />
          </FormControl>
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
