import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Avatar, Tooltip } from "@mui/material";
import { lessonListingApiNew } from "src/DAL/lessons/lessons";
import { useEffect, useState } from "react";
import { s3baseUrl } from "src/config/config";
import { CircularProgress } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { makeStyles } from "@mui/styles";
import ActiveLastBreadcrumb from "src/components/ActivePaths";
import CustomMUITable from "src/components/CustomMUITable";
import { dd_date_format } from "src/utils/constant";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { deleteLessonApi } from "src/DAL/lessonDocument/LessonDocument";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function LessonListing() {
  const id = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { state } = useLocation();
  const [lesson, setLesson] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  console.log(state, "statestatestate");

  const EMPTY_FILTER = {
    search_text: "",
  };

  const [filterState, setFilterState] = useState(EMPTY_FILTER);

  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },
    {
      title: `${id.slug}`,
      navigation: `/programmes/programmes-detail/${id.slug}`,
      active: false,
    },
    {
      title: "Programme Lessons",
      navigation: null,
      active: true,
    },
  ];

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterState.search_text = searchText;
    getLessonsList(filterState);
    localStorage.setItem("lessons_list", JSON.stringify(filterState));
  };

  const getLessonsList = async (filterState) => {
    setIsLoading(true);
    let postData = {
      search_text: filterState.search_text,
    };

    let result = await lessonListingApiNew(
      id.slug,
      page,
      rowsPerPage,
      postData
    );
    if (result.code == 200) {
      setIsLoading(false);
      setLesson(result.lesson);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
    }
  };

  const handleDetail = (row) => {
    navigate(`/programmes/lessons-detail/${row.lesson_slug}`, {
      state: row,
    });
  };

  const handleEdit = (value) => {
    navigate(`/programmes/edit-lessons/${value.lesson_slug}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async (value) => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteLessonApi(deleteDoc?.lesson_slug);
    if (result.code === 200) {
      getLessonsList(filterState);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Detail",
      icon: "eva:eye-fill",
      handleClick: handleDetail,
    },
  ];

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  localStorage.setItem("program_slug", id.slug);

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },

    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "title",
      label: "Title",
      renderData: (row) => {
        const iconStyle = {
          color: "blue !important",
        };
        let title = "N/A";
        if (row.title) {
          title = row.title;
        }
        return (
          <div
            onClick={() =>
              navigate(`/programmes/lessons-detail/${row.lesson_slug}`, {
                state: row,
              })
            }
            className="d-flex pointer"
          >
            <Tooltip
              title={row.status === false ? "Inactive" : "Active"}
              className="pointer"
            >
              <CircleIcon
                style={iconStyle}
                className={`transation-status-icon ${
                  row.status === false ? "Inactive-classs" : "Active-class"
                } `}
              />
            </Tooltip>

            <div>
              <p className="mb-0 " style={{ cursor: "pointer" }}>
                {title}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      id: "image",
      label: "image",
      renderData: (row) => {
        return (
          <Avatar
            src={
              row?.lesson_images?.thumbnail_1
                ? s3baseUrl + row?.lesson_images?.thumbnail_1
                : ""
            }
            alt={row.lesson_slug}
          />
        );
      },
    },
    { id: "order", label: "Order", alignRight: false },
    {
      id: "createdAt",
      label: "Created At",
      alignRight: false,
      renderData: (row) => {
        let createdAt = "N/A";
        if (row.createdAt) {
          createdAt = dd_date_format(row.createdAt);
        }
        return <p className="mb-0">{createdAt}</p>;
      },
    },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("lessons_list");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterState(filter_data);
    getLessonsList(filter_data);
    filter_data;
  }, [rowsPerPage, page]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container">
        <div className="row mobile-margin display-flex">
          <div>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </div>
        </div>
        <div className=" text-end">
          <button
            className="small-contained-button"
            onClick={() =>
              navigate(`/programmes/add-lessons`, {
                state: id.slug,
              })
            }
          >
            Add Lesson
          </button>
        </div>
        <div className="row section-space">
          <div className="col-12">
            <h1>
              {state?.name ? state?.name : lesson[0]?.program[0]?._id?.title}
            </h1>
          </div>
        </div>
        <div className="row">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={lesson}
            MENU_OPTIONS={MENU_OPTIONS}
            className="card-with-background"
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
